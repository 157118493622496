import React, { useState } from "react";
import LogOut from '../../assets/logout.svg'
import { useNavigate } from 'react-router-dom';
import { post } from "../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../../state";

export function Logout({ isDropdownOpen, setIsDropdownOpen }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Token = useSelector((state) => state.token);

    const handleLogout = async () => {
        try {
            await post('/user/logout', { udid: "123456789" }, {}, Token)
            dispatch(setLogout())
            localStorage.clear();
            navigate('/');
        }
        catch (err) {
            console.log("err", err);
        }
        finally {
            setIsDropdownOpen(false)
        }
    }
    return (
        <>
            {isDropdownOpen && (
                <div
                    className="fixed cursor-pointer delay-300 lg:top-14 lg:right-14 top-[32px] right-1 w-[112px] bg-white rounded"
                    onClick={() => handleLogout()} >
                    <ul className="flex justify-between pl-5 items-center">
                        <li className="lg:py-2 py-1 lg:text-[14px] text-[12px] ">Log Out</li>
                        <img className="w-[40px] h-[40px]" src={LogOut} alt="Logout" />
                    </ul>
                </div>
            )}
        </>
    );
}