import React from 'react';
const Inputfield = ({
    Labelname,
    type,
    placeholder,
    labelstyle,
    inputStyle,
    divstyle,
    name,
    htmlFor,
    id,
    onChange,
    values,
    errors,
    touched,
    selected,
    onBlur,
    value,
    isEditing,
    disabled,
    maxLength,
    pattern

}) => {
    return (
        <div className={divstyle}>
            <label htmlFor={htmlFor} className={labelstyle}>
                {Labelname}
            </label>

            {
                type === "radio" ?
                    <input
                        id={id}
                        name={name}
                        onChange={onChange}
                        value={values}
                        className={inputStyle}
                        checked={selected}
                        type={type}
                        placeholder={placeholder}
                    />
                    :
                    <div>
                        {
                            isEditing ? <>
                                <input
                                    type={type}
                                    placeholder={placeholder}
                                    id={name}
                                    name={name}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    className={inputStyle}
                                    disabled={disabled}
                                // disabled={!isEditing}

                                />


                            </>
                                :
                                <div>
                                    <input
                                        id={id}
                                        name={name}
                                        onChange={onChange}
                                        value={values}
                                        className={inputStyle}
                                        type={type}
                                        maxLength={maxLength}
                                        pattern={pattern}
                                        placeholder={placeholder}
                                        disabled={disabled}
                                    />
                                    {errors && touched && (
                                        <div className='text-start text-red-500'>{errors}</div>
                                    )}
                                </div>
                        }
                    </div>
            }
        </div>
    );
};
export default Inputfield;


