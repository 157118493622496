import React from 'react';

export function ImageModal({ imageUrl, onClose }) {

    return (
        <>
            <div className="justify-between items-center flex   fixed inset-0 z-50 ">
                <div className="w-[600px]  p-20 mx-auto justify-start">
                    <div className="border-0 rounded-lg   shadow-lg relative flex flex-col justify-around bg-white ">
                        <div className="flex bg-Appcolor p-5  border-b border-solid border-blueGray-200 rounded-t">
                            <h3 className="lg:text-[18px] text-[16px] w-full flex justify-center mx-auto text-white font-medium">
                                Image
                            </h3>
                            <button
                                className=" w-0 h-2 rounded-2xl mt-[-35px] mr-[-2px]"
                                onClick={() => onClose(null)}  >
                                <span className="bg-white w-8 h-8 rounded-3xl text-black text-2xl block ">
                                    ×
                                </span>
                            </button>
                        </div>
                        <div className="p-4 lg:my-1 mx-auto"  >
                            <div className="relative lg:mt-[20px] mt-[10px] flex mx-auto    w-[100%] custom-scrollbar">
                                <img src={imageUrl} alt="Selected Image" className="max-w-full max-h-[500px]" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

