import React, { useState, useEffect } from "react";
import Inputfield from "../Inputfiled";
import Dropdown from "../Dropdown";
import { useFormik } from "formik";
import { Formschema } from "../../utils/schema";
import { post, get } from "../../utils/axiosInstance";
import Searchcarttwo from "./Searchcarttwo";
import moment from "moment";
import Spinner from "../Spinner";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setExtraCart } from "../../state";
import Toast from "../Toast";

const Form = ({ switchComponent }) => {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.token);
  const storedAddress = useSelector((state) => state.address);
  const cartData = useSelector((state) => state.assigned_cart);
  const extraCart = useSelector((state) => state.extra_cart);
  const selectedVendor = useSelector((state) => state.selectedVendor);
  const user_selected_vendor_id = useSelector((state) => state.user_selected_vendor_id);
  const [categories, setCategories] = useState([]);
  const [AddCalender, setShowAddCalender] = useState(false);
  const [showAdditionalComponent, setShowAdditionalComponent] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [loader, setLoader] = useState(false);
  const [bookedDates, setBookedDates] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dates, setDates] = useState([]);
  useEffect(() => {
    getUserData();
    const bookingDates = localStorage.getItem("dateArray");

    setBookedDates(bookingDates);
  }, []);

  const getUserData = () => {
    get("/category", {}, {}, Token)
      .then((res) => {
        const categoryData = res.response.data.categories;
        setCategories(categoryData);
      })

      .catch((err) => {
        Toast({ message: err.response.data.error.messages[0], isError: true });
      });
  };

  const formik = useFormik({
    initialValues: {
      address: storedAddress ? storedAddress?.full_address : "",
      cart: "",
      user: "No",
    },

    validationSchema: Formschema,
    onSubmit: async (values) => {
      try {
        if (isLoading) {
          return;
        }

        setIsLoading(true);

        if (values.address && values.cart) {
          let preStartDate =
            formik.values.user === "Yes"
              ? dates[0].format("MM/DD/YYYY")
              : moment(start).format("MM/DD/YYYY");
          let preEndDate =
            formik.values.user === "Yes"
              ? dates[1].format("MM/DD/YYYY")
              : moment(end).format("MM/DD/YYYY");

          if (moment(preStartDate).isSame(preEndDate, 'day')) {
            Toast({ message: "Cannot Book One Day", isError: true });
            setIsLoading(false);
            return;
          }
          let durationInDays = moment(preEndDate).diff(moment(preStartDate), 'days');

          if (durationInDays < selectedVendor?.minimum_rentals_days) {
            Toast({ message: `Cannot Book Less Than ${selectedVendor?.minimum_rentals_days ?? 1} Days`, isError: true });
            setIsLoading(false);
            return;
          }

          let arr = [];

          if (extraCart && Array.isArray(extraCart)) {
            extraCart.forEach((existingCart) => {
              arr.push(existingCart.id);
            });
          }

          arr.push(cartData.id);

          const vendorId = selectedVendor?.id ?? user_selected_vendor_id

          const response = await post(
            "/cart/independent-carts",
            {
              vendor_id: vendorId,
              start_date: preStartDate,
              end_date: preEndDate,
              category_id: values.cart.id,
              latitude: storedAddress.latitude,
              longitude: storedAddress.longitude,
              cart_ids: arr,
            },
            {},
            Token
          );

          const responseData = response.response.data.independent_cart;
          let array = []
          array.push(responseData)
          dispatch(setExtraCart(array.concat(extraCart)));
          setShowAdditionalComponent(true);
          setLoader(false);
        } else {
          Toast({ message: "Missing required fields for adding the LSV", isError: true });
        }
      } catch (err) {
        Toast({ message: "No LSV Found!", isError: true });

      } finally {
        setIsLoading(false);
      }
    },
  });

  let start = localStorage.getItem("startDate");
  let end = localStorage.getItem("endDate");

  const handleSaveAndContinueClick = async () => {
    const finalSelectedDates = AddCalender
      ? selectedDates
      : formik.values.selectedDates;
    try {
      setSelectedDates(finalSelectedDates);
      switchComponent("Searchcartone");
    } catch (error) {
      console.log(error);
    }
  };

  const _renderCalender = () => {
    return (
      <Calendar
        minDate={moment(start).format("YYYY/MM/DD")}
        maxDate={moment(end).format("YYYY/MM/DD")}
        style={{ width: "100%" }}
        range={true}
        dateSeparator=" to "
        rangeHover
        calendarPosition="top-left"
        fixMainPosition
        onChange={(e) => setDates(e)}
      />
    )
  }
  if (loader) {
    return (
      <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
        <Spinner className="spinner" isLoading={loader} />
      </div>
    );
  }
  return (
    <div className="bg-[#FFFFFF] rounded-[10px] flex flex-col justify-center items-center w-[100%] h-[670px]">
      <p className="lg:my-[27px] my-[30px] text-[22px] font-bold text-Textdarkcolor w-[90%]">
        Search LSV
      </p>

      <div className="w-full h-[100%] p-4  ">
        <div className="text-center text-Textdarkcolor pt-[15px] text-[18px] font-semibold">
          <p>Add Additional LSV</p>
        </div>
        {/* <div> */}
          <form onSubmit={formik.handleSubmit} className=" w-full">
            <div className=" py-3  custom-scrollbar h-[400px] mx-auto lg:w-[70%]  w-[90%]">
              <Inputfield
                divstyle={` `}
                labelstyle="flex text-[18px] text-Inputtextcolor font-medium"
                inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none text-black border-b-[1px] border-[#f5f5f5]"
                Labelname="Address"
                type="text"
                name="address"
                value={formik.values.address}
                isEditing={true}
                disabled={true}
              />
              <div className="mt-3 flex flex-row gap-4 justify-center w-full mx-3">
                {categories.map((card) => (
                  <div
                    key={card.id}
                    className={`relative border p-4 rounded-lg cursor-pointer transition-transform duration-300 transform hover:scale-105 shadow-lg ${formik.values.cart === card ? 'bg-blue-500 text-white' : 'bg-white'
                      }`}
                    onClick={() => {
                      formik.setFieldValue("cart", card);
                    }}
                  >
                    <div className="flex flex-col items-center">
                      <img src={card.media} alt={card.name} className="w-32 h-32 object-cover rounded-md mb-4 shadow-md" />
                      <h2 className="text-lg font-semibold mb-2">{card.name}</h2>
                      <p className="text-sm">{card.description}</p>
                    </div>
                    {formik.values.cart === card && (
                      <div className="absolute top-0  right-0 bg-green-500 text-white p-1 rounded-se-lg rounded-es-lg justify-center align-middle">
                        <p className="justify-center align-middle">✓</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {/* <Dropdown
                  labelstyle="flex text-[18px]  text-Textdarkcolor font-medium"
                  divstyle="mt-1 w-full border-b-[1px] focus:outline-none border-[#f5f5f5] "
                  label="LSV Preference"
                  id="cart"
                  name="cart"
                  placeholder="Choose your preferred LSV passengers"
                  options={categories.map((category) => ({
                    id: category.id,
                    value: category.name,
                    label: category.description,
                    item: category,
                  }))}
                  value={formik.values.cart?.name ?? ""}
                  onChange={(e) => {
                    formik.setFieldValue("cart", e.target.value);
                  }}
                  error={formik.errors.cart}
                  touched={formik.touched.cart}
                /> */}

              <div className="lg:mt-[31px] mt-[20px] w-[98%] mx-auto pb-10">
                <label className="flex text-[18px] text-Textdarkcolor font-medium">
                  Do you want to change your extra LSV reservation date?{" "}
                </label>
                <div className="flex items-center mt-2  ">
                  <Inputfield
                    divstyle="flex flex-row-reverse relative"
                    labelstyle="pl-3 text-Textdarkcolor font-medium"
                    inputStyle="pl-3"
                    Labelname="Yes"
                    type="radio"
                    name="user"
                    isEditing={true}
                    onChange={() => {
                      formik.setFieldValue("user", "Yes");
                      setShowAddCalender(true);
                    }}
                    selected={formik.values.user === "Yes"}
                  />

                  <Inputfield
                    divstyle="flex flex-row-reverse pl-3"
                    labelstyle="pl-3  lg:text-[18px] text-[14px] text-Textdarkcolor font-medium"
                    inputStyle="pl-3"
                    Labelname="No"
                    type="radio"
                    name="user"
                    isEditing={true}
                    onChange={() => {
                      formik.setFieldValue("user", "No");
                      setShowAddCalender(false);
                    }}
                    selected={formik.values.user === "No"}
                  />
                </div>

                {AddCalender && (
                  <div className="mt-5">
                    <div>
                      <div className="mt-5">
                        {bookedDates !== null && _renderCalender()}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {showAdditionalComponent && (
                <div className=" w-full mt-10">
                  <Searchcarttwo apiData={extraCart} showDelete={false} />
                </div>
              )}
            </div>

            <div className="flex justify-between w-[90%] mx-auto lg:my-10 my-7">
              <button
                className="bg-Btncolor text-Textdarkcolor lg:text-[16px] text-[12px] rounded-[10px] lg:w-[145px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[110px] h-[40px]"
                onClick={() => switchComponent("Searchcartone")}
              >
                Back
              </button>
              {!showAdditionalComponent ? (
                <button
                  className="bg-Appcolor text-white lg:text-[16px] text-[12px] rounded-[10px] lg:w-[150px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[130px] h-[35px]"
                  type="submit"
                  disabled={isLoading}
                >
                  {" "}
                  Add{" "}
                </button>
              ) : (
                <button
                  className={`bg-Appcolor text-white lg:text-[16px] text-[12px] rounded-[10px] lg:w-[150px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[130px] h-[35px] `}
                  onClick={() => handleSaveAndContinueClick()}
                  disabled={isLoading}
                >
                  Save and Continue
                </button>
              )}
            </div>
          </form>
        {/* </div> */}
      </div>
    </div>
  );
};
export default Form;
