import React, { useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Close from "../../assets/Png/Closemodal.png";

export function TermConditionModal({
  setTermCheck,
  showTermModal,
  setTermShowModal,
  TermData,
  isFromSignup,
  PrivacyData
}) {



  const [radioChecked, setRadioChecked] = useState(false);

  const handleRadioChange = () => {
    if (!radioChecked) {
      setRadioChecked(true);
    }
  };

  const handleContinueClick = () => {
    setTermCheck("Termcheckbox", true);
    setTermShowModal(false);
  };

  return (
    <>
      {showTermModal ? (
        <>
          <div className="justify-center items-center flex fixed inset-0 z-50   ">
            <div className="lg:w-[50%]    fixed p-5  md:px-20 mx-auto">
              <div className="border-0 rounded-lg shadow-lg relative md:h-full h-[450px]  flex flex-col w-full bg-white outline-none focus:outline-none ">
                <div className="flex bg-Appcolor p-5 border-b border-solid border-blueGray-200 rounded-t-lg ">
                  <h3 className="lg:text-[18px] text-[14px] w-full flex justify-center mx-auto text-white font-medium">
                    Rental's Term And Condition
                  </h3>

                  <button
                    className="w-0 h-2 rounded-2xl lg:mt-[-35px] mt-[-32px] lg:mr-[-2px] mr-[-4px]"
                    onClick={() => {
                      {
                        isFromSignup ? (
                          <>{setTermShowModal(false)}</>
                        ) : (
                          setTermShowModal(false)
                        );
                      }
                    }}
                  >
                    <span className="  lg:w-[34px] lg:h-[32px] w-[27px] h-[25px]  block  ">
                      <img src={Close} />
                    </span>
                  </button>
                </div>
                <div className="w-[90%] mx-auto custom-scrollbar xl:h-[600px] md:h-[400px]  ">
                  <div className="lg:w-[90%] w-[95%] my-10  mx-auto">
                    <p className="font-semibold text-Textdarkcolor mt-[20px] lg:text-[16px] text-[12px]">
                      Rentals Reimagined Agreement
                    </p>
                    <div className="flex justify-between ">
                      <div className="lg:text-[14px]   text-[10px]  mt-[18px] w-full text-[Textlitecolor]">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: TermData?.description ?? "",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  {/* Privacy Policy */}

                  <div className="lg:w-[90%] w-[95%] my-10  mx-auto">
                    <p className="font-semibold text-Textdarkcolor mt-[20px] lg:text-[16px] text-[12px]">
                      Rentals Reimagined Privacy Policy
                    </p>
                    <div className="flex justify-between ">
                      <div className="lg:text-[14px]   text-[10px]  mt-[18px] w-full text-[Textlitecolor]">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: PrivacyData?.description ?? "",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>


                  {/*  */}

                  <div className="flex  lg:mt-[0px] mt-[15px] ml-3  p-6 rounded-b">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-radio h-4 w-4 text-Appcolor"
                        checked={radioChecked}
                        onChange={handleRadioChange}
                      />
                      <span className="ml-2">
                        I agree to the terms and conditions
                      </span>
                    </label>
                  </div>

                  <div className="flex items-center lg:mt-[10px] mt-[15px] justify-center p-6 rounded-b">
                    <button
                      className={`bg-Appcolor text-white font-medium px-2 py-3 rounded-md w-[240px] text-sm ${
                        !radioChecked && "opacity-50 cursor-not-allowed"
                      }`}
                      type="button"
                      onClick={handleContinueClick}
                      disabled={!radioChecked}
                    >
                      Continue
                    </button>
                  </div>

                  {/*  */}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}


