import React, { useState, useEffect } from "react";
import Inputfield from "../Inputfiled";
import { useFormik } from "formik";
import { Profileschema } from "../../utils/schema";
import { get, patch } from "../../utils/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from '../Spinner';
import { ImageModal } from '../Settings/Imagemodal';
import Imageview from '../../assets/imageview.svg'
import { useSelector } from "react-redux";

const Availablecart = ({ user }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [loader, setLoader] = useState(false);
    // const [userData, setUserData] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    // const Token = useSelector((state) => state.token);
    // const user = useSelector((state) => state.user);

    // console.log("Availablecart Ki value", user);

    useEffect(() => {
        setLoader(true);
        getUserData();
    }, []);

    const getUserData = () => {
        setLoader(true)
        formik.setValues({
            propertyaddress: user?.address?.property_address || '',
            city: user?.address?.city || '',
            unit: user?.address?.unit || '',
            state: user?.address?.state || '',
            zip: user?.address?.zip || '',
            garagecode: user?.address?.garage_code || '',
            gatecode: user?.address?.gate_code || '',
            housecode: user?.address?.house_code || '',
            latitude: user?.address?.latitude || '',
            longitude: user?.address?.longitude || '',
            color: user?.cart?.color || '',
            make: user?.cart?.make || '',
            model: user?.cart?.model || '',
            cart: user?.cart?.cart_type || '',
            year: user?.cart?.year || '',
            vin: user?.cart?.vin || '',
            licenseplatenumber: user?.cart?.license_plate_number || '',
            drive: user?.cart?.drive || '',
            serialnumber: user?.cart?.serial_number || '',
            image: user?.cart?.image || '',
        });
    };

    const formik = useFormik({
        initialValues: {
            propertyaddress: '',
            city: '',
            unit: '',
            state: '',
            zip: '',
            garagecode: '',
            gatecode: '',
            housecode: '',
            latitude: '',
            longitude: '',
            color: '',
            make: '',
            model: '',
            cart: '',
            year: '',
            vin: '',
            licenseplatenumber: '',
            drive: '',
            serialnumber: '',
            image: '',
        },
        // validationSchema: Profileschema,

    });

    const openImageModal = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    const handleLicenseFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            formik.setFieldValue('image', URL.createObjectURL(file));
        }
    };

    // const handleEditClick = () => {
    //     setIsEditing(true);
    // };

    // if (loader) {
    //     return (
    //         <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
    //             <Spinner className="spinner" isLoading={loader} />
    //         </div>
    //     );
    // } else {
    return (
        <div className='bg-[#FFFFFF] rounded-[10px] flex flex-col justify-center items-center w-[100%] md:h-[670px]  h-[87vh]'>
            <p className='flex justify-between border-b-[1px] border-[#EDF0F6]   w-[90%] '>
                <span className='lg:text-[22px] text-[16px] font-bold mt-[27px]'>Property Address & LSV Details</span>
                {/* {!isEditing && (
                        <img
                            src={Edit}
                            className='w-[30px] mt-[27px] h-[30px] cursor-pointer'
                            onClick={handleEditClick}
                        />
                    )} */}
            </p>

            <div className='py-3 lg:w-[70%] w-[90%] custom-scrollbar'>
                <form onSubmit={formik.handleSubmit}>
                    {/* <img src={Profilecard} className='w-[216px] mt-10 h-[137px] mx-auto' /> */}

                    <div>
                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Property Address"
                            type="text"
                            name="propertyaddress"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.propertyaddress}
                            errors={formik.errors.propertyaddress}
                            touched={formik.touched.propertyaddress}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="City"
                            type="text"
                            name="city"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                            errors={formik.errors.city}
                            touched={formik.touched.city}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Unit"
                            type="text"
                            name="unit"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.unit}
                            errors={formik.errors.unit}
                            touched={formik.touched.unit}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="State"
                            type="text"
                            name="state"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.state}
                            errors={formik.errors.state}
                            touched={formik.touched.state}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Zip Code"
                            type="text"
                            name="zip"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.zip}
                            errors={formik.errors.zip}
                            touched={formik.touched.zip}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Garage Code"
                            type="text"
                            name="garagecode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.garagecode}
                            errors={formik.errors.garagecode}
                            touched={formik.touched.garagecode}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Gate Code"
                            type="text"
                            name="gatecode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.gatecode}
                            errors={formik.errors.gatecode}
                            touched={formik.touched.gatecode}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="House Code"
                            type="text"
                            name="housecode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.housecode}
                            errors={formik.errors.housecode}
                            touched={formik.touched.housecode}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Latitude"
                            type="text"
                            name="latitude"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.latitude}
                            errors={formik.errors.latitude}
                            touched={formik.touched.latitude}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Longitude"
                            type="text"
                            name="longitude"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.longitude}
                            errors={formik.errors.longitude}
                            touched={formik.touched.longitude}
                            isEditing={true}
                            disabled={!isEditing}
                        />


                        {/* Cart */}
                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Color"
                            type="text"
                            name="color"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.color}
                            errors={formik.errors.color}
                            touched={formik.touched.color}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Make"
                            type="text"
                            name="make"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.make}
                            errors={formik.errors.make}
                            touched={formik.touched.make}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Model"
                            type="text"
                            name="model"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.model}
                            errors={formik.errors.model}
                            touched={formik.touched.model}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="LSV Type"
                            type="text"
                            name="cart"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.cart}
                            errors={formik.errors.cart}
                            touched={formik.touched.cart}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Year"
                            type="text"
                            name="year"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.year}
                            errors={formik.errors.year}
                            touched={formik.touched.year}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Vin"
                            type="text"
                            name="vin"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.vin}
                            errors={formik.errors.vin}
                            touched={formik.touched.vin}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="License Plate Number"
                            type="text"
                            name="licenseplatenumber"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.licenseplatenumber}
                            errors={formik.errors.licenseplatenumber}
                            touched={formik.touched.licenseplatenumber}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px]  md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Drive"
                            type="text"
                            name="drive"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.drive}
                            errors={formik.errors.drive}
                            touched={formik.touched.drive}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <Inputfield
                            labelstyle="flex text-[18px] md:mt-[41px] mt-[21px] text-Inputtextcolor font-medium"
                            inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                            Labelname="Serial Number"
                            type="text"
                            name="serialnumber"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.serialnumber}
                            errors={formik.errors.serialnumber}
                            touched={formik.touched.serialnumber}
                            isEditing={true}
                            disabled={!isEditing}
                        />

                        <div className="flex flex-col">
                            <label className='flex text-[18px] mt-[21px] text-Inputtextcolor font-medium'>Cart</label>
                            <label className="relative inline-flex items-center mt-2 justify-center w-[100%] md:h-[500px] border-[1px] rounded-[10px] border-gray-300 cursor-pointer bg-[#F8F8F8]" >
                                <input
                                    type="file"
                                    accept="image/png, image/jpeg, image/jpg"
                                    name='image'
                                    className="absolute object-contain inset-0 w-full h-full opacity-0 cursor-pointer"
                                    onChange={handleLicenseFileChange}
                                    onBlur={formik.handleBlur}
                                    onFocus={() => formik.setFieldTouched('image', true)}
                                    disabled={!isEditing}
                                />
                                {formik.values.image ? (
                                    <div className='w-full flex flex-row-reverse  relative'>
                                        <img src={formik.values.image} alt="User Insurance Thumbnail" className="w-[100%] md:h-[500px] object-fill rounded-[10px]" draggable={false} />
                                        {/* <img src={Imageview} alt="User Insurance Thumbnail" className="w-[21px] object-fill mr-[7px] absolute z-50 bottom-0 h-[28px]   ml-auto mt-auto"

                                        onClick={() => openImageModal(formik.values.image)} 

                                        /> */}
                                    </div>

                                ) : (
                                    <div className="w-full h-full flex items-center justify-center">
                                        <span className="text-gray-500">No Image Selected</span>
                                    </div>
                                )}
                            </label>
                            {formik.errors.image && formik.touched.image && (
                                <div className='text-start text-red-500 '>{formik.errors.image}</div>
                            )}

                        </div>
                    </div>
                </form >
            </div >

            <div className="h-[200px]">
                {selectedImage && (
                    <ImageModal imageUrl={selectedImage} onClose={() => setSelectedImage(null)} />
                )}
            </div>
            <ToastContainer limit={1} />
        </div >
    );
    // }
};


export default Availablecart;
