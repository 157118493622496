import React, { useState } from 'react';
import Hidden from '../assets/hidden.svg'
import Show from '../assets/show.svg'

const PasswordInput = ({ label, name, value, onChange, onBlur, error, touched, labelstyle, innerdiv, placeholder }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <div>
            <label className={`flex  md:mt-[41px] mt-[20px] text-Textdarkcolor font-medium ${labelstyle}`}>{label}</label>
            <div className={`relative ${innerdiv}`}>
                <input
                    type={showPassword ? 'text' : 'password'}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    className="lg:mt-1 mt-0 w-full pl-0 py-2 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                    placeholder={placeholder}
                />
                <span
                    className="absolute right-2 top-2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                >
                    {showPassword ? <img src={Show} className='lg:w-5 w-3 lg:h-5 h-3' /> : <img src={Hidden} className='lg:w-5 w-3 lg:h-5 h-3' />}
                </span>
            </div>
            {error && touched && <div className="text-red-500">{error}</div>}
        </div>
    );
};

export default PasswordInput;
