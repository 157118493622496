import React, { useState } from 'react';
import { Cardmodal } from './Cardmodal';
import { Bankcardmodal } from './Bankcardmodal';
import { Selectcardmodal } from './Selectcardmodal';

const Modal = ({ activeComponent, setActiveComponent, SummaryPage }) => {
    const [cardDetails, setCardDetails] = useState(null);
    const handleCardDetails = (details) => {
        setCardDetails(details);
    };



    const renderComponent = () => {
        switch (activeComponent) {
            case "A":
                return <Cardmodal SummaryPage={SummaryPage} switchComponent={setActiveComponent} handleCardDetails={handleCardDetails} />;
            case "B":
                return <Bankcardmodal switchComponent={setActiveComponent} handleCardDetails={handleCardDetails} />;
            case "C":
                return <Selectcardmodal switchComponent={setActiveComponent} handleCardDetails={handleCardDetails} />;
            default:
                return null;
        }
    };
    return (
        <div>
            {renderComponent()}
        </div>
    );
};

export default Modal;
