// import React from "react";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const Toast = ({ message, isError }) => {
//     return (
//         toast(message, {
//             type: isError ? "error" : "success",
//             position: "bottom-right",
//             closeButton: false,
//             closeOnClick: false,
//             draggable: false,
//             pauseOnHover: false,
//             autoClose: 2000,
//         })
//     );
// };

// export default Toast;



  
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toast = ({ message, isError, position = "bottom-right", autoClose= 2000 }) => {
    return toast(message, {
        type: isError ? "error" : "success",
        position: position,
        closeButton: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: false,
        autoClose 
    });
};
 
 
export default Toast;
















