import React, { useEffect, useState } from "react";
import Inputfield from "../../components/Inputfiled";
import Button from "../../components/Button";
import LoginImg from "../../assets/loginimg.svg";
import Plus from "../../assets/plus.svg";
import { useFormik } from "formik";
import "../../../src/index.css";
import PasswordInput from "../Password";
import { Signupvalidationschema } from "../../utils/schema";
import Dropdown from "../../components/Dropdown";
import Fileupload from "../../components/Fileupload";
import Logo from "../../assets/Logo.svg";
import { useNavigate } from "react-router-dom";
import { Aggrementmodal } from "../../components/Modals/Aggrementmodal";
import { post, get } from "../../utils/axiosInstance";
import Toast from "../Toast";
import { useDispatch } from "react-redux";
import { setLogin } from "../../state/index";
import countriesData from "./Countries.json";
import { ToastContainer } from "react-toastify";
import { TermConditionModal } from "../Modals/Termconditionmodal";
import { PrivacyModal } from "../Modals/PrivacyModal";

const Signup = () => {
  const navigate = useNavigate();
  // const [signature, setSignature] = useState(null);
  const [agreementData, setAgreementData] = useState("");
  const [termCondition, setTermCondition] = useState("");
  const [privacy, setPrivacy] = useState("");

  const [responseData, setResponseData] = useState("");
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [termCheck, setTermCheck] = useState(false);
  const [phoneCheckValue, setPhoneCheckValue] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTermModal, setShowTermModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const openTermModal = () => {
    setShowTermModal(true);
  };

  const openPrivacyModal = () => {
    setShowPrivacyModal(true);
  };

  const getDialCode = (countryName) => {
    const selectedCountryData = countriesData.find(
      (country) => country.name === countryName
    );
    return selectedCountryData ? selectedCountryData.dial_code : "";
  };

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",

      country: "United States", // new property for country name
      dial_code: "+1", // new property for dial code
      phone: "",

      address1: "",
      city: "",
      state: "",
      zip: "",
      password: "",
      confirmpassword: "",
      user_type: "guest",
      checkbox: "",
      Termcheckbox: "",
      license: "",
      phoneCheck: "",
      insurance: "",
    },
    validationSchema: Signupvalidationschema,
    onSubmit: (values) => {
      handleSubmit({ ...values });
    },
  });

  const handleSubmit = (e) => {
    const firstErrorElement = document.querySelector(".text-red-500");
    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    var formdata = new FormData();
    if (formik.values.checkbox) {
      formdata.append("first_name", e.first_name);
      formdata.append("last_name", e.last_name);
      formdata.append("email", e.email);
      formdata.append("phone", e.phone);
      formdata.append("password", e.password);
      formdata.append("user_type", e.user_type);
      formdata.append("zip", e.zip);
      formdata.append("state", e.state);
      formdata.append("city", e.city);
      formdata.append("address1", e.address1);
      formdata.append("country", e.country);
      formdata.append("country_code", e.dial_code);
      if (e.user_type === "guest") {
        formdata.append("insurance", e.insurance);
        formdata.append("license", e.license);
      }
      setIsLoading(true);
      post("/register", formdata, {}, {})
        .then((res) => {
          if ("response" in res) {
            setIsLoading(false);
            localStorage.setItem("token", res.response.data.user.token);
            localStorage.setItem(
              "user",
              JSON.stringify(res.response.data.user)
            );
            localStorage.setItem("user_type", res.response.data.user.user_type);
            localStorage.setItem("userId", res.response.data.user.id);
            dispatch(
              setLogin({
                user: res.response.data.user,
                token: res.response.data.user.token,
                user_type: res.response.data.user.user_type,
              })
            );
            if (res.response.data.user.user_type == "guest") {
              navigate("/home");
            } else {
              navigate("/vendors");
            }
          } else {
            setIsLoading(false);
            Toast({ message: "Something went wrong", isError: true });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          Toast({
            message: err.response.data.error.messages[0],
            isError: true,
          });
        });
    } else {
      Toast({ message: "Please Check The Agreement", isError: true });
    }
  };

  const handleCountryChange = (event) => {
    const selectedCountryName = event.target.value;
    const dialCode = getDialCode(selectedCountryName);

    formik.handleChange(event);
    formik.setValues({
      ...formik.values,
      country: selectedCountryName,
      dial_code: dialCode,
      phone: "", // Reset krna hy phone ki field ko jab counrty change hogi
    });
  };

  const handlePhoneNumberChange = (event) => {
    formik.handleChange(event);
    formik.setValues({
      ...formik.values,
      phone: event.target.value,
    });
  };

  useEffect(() => {
    let data = agreementData === "property_owner" ? "property-owner" : "guest";
    get(`/agreement/${data}`)
      .then((res) => {
        const response = res?.response?.data?.agreement;
        if (response) {
          setResponseData(response);
        } else {
          Toast({ message: "Something went wrong", isError: true });
        }
      })
      .catch((err) => {
        Toast({
          message: err?.response?.data?.error?.messages[0],
          isError: true,
        });
      });
  }, []);
 

  useEffect(() => {
    get(`/content/content/terms-and-conditions`)
      .then((res) => {
        const response = res?.response?.data?.content;
        if (response) {
          setTermCondition(response);
        } else {
          Toast({ message: "Something went wrong", isError: true });
        }
      })
      .catch((err) => {
        Toast({
          message: err?.response?.data?.error?.messages[0],
          isError: true,
        });
      });
  }, []);



  useEffect(() => {
    get(`/content/content/privacy-and-policy`)
      .then((res) => {
        const response = res?.response?.data?.content;
        if (response) {
          console.log("response=========>",response);
          setPrivacy(response);
        } else {
          Toast({ message: "Something went wrong", isError: true });
        }
      })
      .catch((err) => {
        Toast({
          message: err?.response?.data?.error?.messages[0],
          isError: true,
        });
      });
  }, []);



 
  return (
    <div className="w-full">
      <div className="w-[50%] md:block hidden bg-Appcolor h-full  fixed  ">
        <div className="flex flex-col justify-center items-center h-full">
          <div className="flex flex-col w-[100%] justify-center mx-auto">
            <p className="text-[36px] font-bold text-center text-white">
              Welcome To Ridewise
            </p>
            <p className="text-[36px] font-bold text-center text-white">by</p>

            <p className="text-[30px] font-bold italic  text-center text-white">
              Rentals Reimagined
            </p>
          </div>
          <img src={LoginImg} className=" w-full h-[478px]  " alt="Logo" />
        </div>
      </div>

      <img
        src={Logo}
        className="block md:hidden mx-auto h-[150px] w-[280px]"
        alt="Logo"
      />

      <div className="md:w-[50%] w-[100%]    flex justify-center items-center   mb-12 ml-auto  md:mb-0  ">
        <form onSubmit={formik.handleSubmit} className="w-[80%] mx-auto">
          <img
            src={Logo}
            className="hidden md:block  w-[300px] h-[180px] mx-auto "
            alt="Logo"
          />

          <h1 className="heading"> Create Account</h1>
          <div className="flex flex-col justify-start md:mt-[45px] mt-[30px]">
            <Inputfield
              labelstyle="flex text-[18px] lg:mt-[41px] mt-[20px] text-Textdarkcolor font-medium"
              inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
              Labelname="First Name"
              type="text"
              placeholder="Enter your First Name"
              name="first_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
              errors={formik.errors.first_name}
              touched={formik.touched.first_name}
            />

            <Inputfield
              labelstyle="flex text-[18px] lg:mt-[41px] mt-[20px] text-Textdarkcolor font-medium"
              inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
              Labelname="Last Name"
              type="text"
              placeholder="Enter your Last Name"
              name="last_name"
              htmlFor="last_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
              errors={formik.errors.last_name}
              touched={formik.touched.last_name}
            />

            <Inputfield
              labelstyle="flex text-[18px] lg:mt-[41px] mt-[20px] text-Textdarkcolor font-medium"
              inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
              Labelname="Email"
              type="email"
              placeholder="Enter your Email"
              name="email"
              htmlFor="email"
              id="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              errors={formik.errors.email}
              touched={formik.touched.email}
            />

            <label className="flex text-[18px] lg:mt-[41px] mt-[20px] text-Textdarkcolor font-medium">
              Phone Number
            </label>
            <div className="w-full mt-1 pl-0 p-3 border-b-[1px] border-[#f5f5f5]">
              <select
                className="md:w-[10%]  w-[16%] focus:outline-none"
                value={formik.values.country}
                onChange={handleCountryChange}
                style={{ maxHeight: "150px" }}
              >
                {/* <option className="text-[10px]" value="">Select</option> */}
                {countriesData.map((country) => (
                  <option
                    className="md:text-[16px] text-[80px]  "
                    key={country.code}
                    value={country.name}
                  >
                    {formik.values.country === country.name ? (
                      <span role="img" aria-label="Flag">
                        {country.flag}
                      </span>
                    ) : (
                      <>
                        <p className=" country-name bg-red-400">
                          {country.name} {country.flag}
                        </p>
                      </>
                    )}
                  </option>
                ))}
              </select>

              {formik.values.country && (
                <>
                  <input
                    className="md:ml-2 ml-1 md:w-[6%] w-[14%] focus:outline-none"
                    type="text"
                    readOnly={!formik.values.country}
                    value={formik.values.dial_code}
                  />
                  <input
                    className="md:w-[65%] ml-1 w-[65%] focus:outline-none"
                    type="tel"
                    value={formik.values.phone}
                    onChange={handlePhoneNumberChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter your Phone Number"
                  />
                </>
              )}
            </div>
            {formik.touched.phone && formik.errors.phone && (
              <div className="text-red-500">{formik.errors.phone}</div>
            )}

            <div className="mt-5">
              <div className="flex">
                <div className="items-start">
                  {" "}
                  {!phoneCheckValue ? (
                    <input
                      id={3}
                      name="phoneCheck"
                      checked={phoneCheckValue}
                      onChange={() => {
                        setPhoneCheckValue(!phoneCheckValue);
                        formik.setFieldValue("phoneCheck", !phoneCheckValue);
                      }}
                      type="checkbox"
                      placeholder="phoneCheck"
                    />
                  ) : (
                    <input
                      id={3}
                      name={"phoneCheck"}
                      checked={phoneCheckValue}
                      type={"checkbox"}
                      placeholder={"phoneCheck"}
                    />
                  )}
                </div>

                <label 
                  className="pl-3 lg:text-[15px] text-[14px] text-Textdarkcolor font-medium"
                >
                  By providing your phone number, you agree to receive text
                  messages from Rentals Reimagined. Message and data rates may
                  apply. Message frequency varies.
                </label>
              </div>

              {formik.errors.phoneCheck &&
                formik.touched.phoneCheck &&
                !phoneCheckValue && (
                  <div className="text-red-500">{formik.errors.phoneCheck}</div>
                )}
            </div>

            <Inputfield
              labelstyle="flex text-[18px] lg:mt-[41px] mt-[20px] text-Textdarkcolor font-medium"
              inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
              Labelname="Address1 "
              type="text"
              placeholder="Enter your Address 1"
              name="address1"
              htmlFor="address1"
              id="address1"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address1}
              errors={formik.errors.address1}
              touched={formik.touched.address1}
            />

            <Inputfield
              labelstyle="flex text-[18px] lg:mt-[41px] mt-[20px] text-Textdarkcolor font-medium"
              inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5] "
              Labelname="Address 2 (Optional)"
              type="text"
              placeholder="Enter your Address 1"
            />

            <Inputfield
              labelstyle="flex text-[18px] lg:mt-[41px] mt-[20px] text-Textdarkcolor font-medium"
              inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
              Labelname="Unit/Apt"
              type="text"
              placeholder="Enter Unit/Apt"
              name="unit"
            />

            <Inputfield
              labelstyle="flex text-[18px] lg:mt-[41px] mt-[20px] text-Textdarkcolor font-medium"
              inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
              Labelname="City"
              type="text"
              placeholder="Enter your City"
              name="city"
              htmlFor="city"
              id="city"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              errors={formik.errors.city}
              touched={formik.touched.city}
            />

            <Dropdown
              labelstyle="flex text-[18px] lg:mt-[41px] mt-[20px] text-Textdarkcolor font-medium"
              divstyle="mt-1 w-full border-b-[1px] focus:outline-none border-[#f5f5f5] "
              label="State"
              id="countries"
              name="state"
              placeholder="Enter State"
              options={[
                { value: "Alabama", label: "Alabama" },
                { value: "Alaska", label: "Alaska" },
                { value: "Arizona", label: "Arizona" },
                { value: "Arkansas", label: "Arkansas" },
                { value: "California", label: "California" },
                { value: "Colorado", label: "Colorado" },
                { value: "Connecticut", label: "Connecticut" },
                { value: "Delaware", label: "Delaware" },
                { value: "Florida", label: "Florida" },
                { value: "Georgia", label: "Georgia" },
                { value: "Hawaii", label: "Hawaii" },
                { value: "Idaho", label: "Idaho" },
                { value: "Illinois", label: "Illinois" },
                { value: "Indiana", label: "Indiana" },
                { value: "Iowa", label: "Iowa" },
                { value: "Kansas", label: "Kansas" },
                { value: "Kentucky", label: "Kentucky" },
                { value: "Louisiana", label: "Louisiana" },
                { value: "Maine", label: "Maine" },
                { value: "Maryland", label: "Maryland" },
                { value: "Massachusetts", label: "Massachusetts" },
                { value: "Michigan", label: "Michigan" },
                { value: "Minnesota", label: "Minnesota" },
                { value: "Mississippi", label: "Mississippi" },
                { value: "Missouri", label: "Missouri" },
                { value: "Montana", label: "Montana" },
                { value: "Nebraska", label: "Nebraska" },
                { value: "Nevada", label: "Nevada" },
                { value: "New Hampshire", label: "New Hampshire" },
                { value: "New Jersey", label: "New Jersey" },
                { value: "New Mexico", label: "New Mexico" },
                { value: "New York", label: "New York" },
                { value: "North Carolina", label: "North Carolina" },
                { value: "North Dakota", label: "North Dakota" },
                { value: "Ohio", label: "Ohio" },
                { value: "Oklahoma", label: "Oklahoma" },
                { value: "Oregon", label: "Oregon" },
                { value: "Pennsylvania", label: "Pennsylvania" },
                { value: "Rhode Island", label: "Rhode Island" },
                { value: "South Carolina", label: "South Carolina" },
                { value: "South Dakota", label: "South Dakota" },
                { value: "Tennessee", label: "Tennessee" },
                { value: "Texas", label: "Texas" },
                { value: "Utah", label: "Utah" },
                { value: "Vermont", label: "Vermont" },
                { value: "Virginia", label: "Virginia" },
                { value: "Washington", label: "Washington" },
                { value: "West Virginia", label: "West Virginia" },
                { value: "Wisconsin", label: "Wisconsin" },
                { value: "Wyoming", label: "Wyoming" },
              ]}
              value={formik.values.state}
              onChange={formik.handleChange}
              error={formik.errors.state}
              touched={formik.touched.state}
            />

            <Inputfield
              labelstyle="flex text-[18px] lg:mt-[41px] mt-[20px] text-Textdarkcolor font-medium"
              inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
              Labelname="Zip Code"
              type="text"
              placeholder="Enter Zip Code"
              name="zip"
              htmlFor="zip"
              id="zip"
              value={formik.values.zip}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errors={formik.errors.zip}
              touched={formik.touched.zip}
            />

            <PasswordInput
              label="Password"
              name="password"
              placeholder="Enter Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.password}
              touched={formik.touched.password}
            />

            <PasswordInput
              label="Confirm Password"
              name="confirmpassword"
              placeholder="Enter Confirm Password"
              value={formik.values.confirmpassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.confirmpassword}
              touched={formik.touched.confirmpassword}
            />

            {formik.values.user_type === "guest" ? (
              <p>
                <Fileupload
                  image={Plus}
                  labelname="Driving License"
                  placeholder="Upload Image"
                  name="license"
                  id="license"
                  onChange={formik.handleChange}
                  value={formik.values.license}
                  error={formik.errors.license}
                  touched={formik.touched.license}
                />

                <Fileupload
                  image={Plus}
                  labelname="Insurance"
                  placeholder="Upload Image"
                  name="insurance"
                  id="insurance"
                  onChange={formik.handleChange}
                  value={formik.values.insurance}
                  error={formik.errors.insurance}
                  touched={formik.touched.insurance}
                />
              </p>
            ) : null}
          </div>

          <div className="lg:mt-[41px] mt-[20px]">
            <div className="flex items-center">
              <Inputfield
                divstyle="flex flex-row-reverse"
                labelstyle="pl-3 lg:text-[18px] text-[14px] text-Textdarkcolor font-medium"
                inputStyle=""
                Labelname="Guest"
                type="radio"
                name="user_type"
                onChange={() => {
                  setAgreementCheck(false);
                  formik.setFieldValue("user_type", "guest");
                  setAgreementData("Guest");
                }}
                selected={formik.values.user_type === "guest"}
              />
              <Inputfield
                divstyle="flex pl-10 flex-row-reverse"
                labelstyle="pl-3 lg:text-[18px] text-[14px] text-Textdarkcolor font-medium"
                inputStyle="pl-3"
                Labelname="Property Owner"
                type="radio"
                name="user_type"
                onChange={() => {
                  formik.setFieldValue("user_type", "property-owner");
                  setAgreementCheck(false);
                  setAgreementData("property_owner");
                }}
                selected={formik.values.user_type === "property-owner"}
              />
            </div>
            {formik.errors.user_type && formik.touched.user_type ? (
              <div className="text-red-500">{formik.errors.user_type}</div>
            ) : null}
          </div>

          <div className="mt-5">
            <div className="flex">
              {!formik.values.checkbox ? (
                <input
                  id={2}
                  name="checkbox"
                  onClick={!formik.values.checkbox ? openModal : openModal}
                  checked={formik.values.checkbox}
                  type="checkbox"
                  placeholder="checkbox"
                />
              ) : (
                <input
                  id={2}
                  name={"checkbox"}
                  checked={formik.values.checkbox}
                  type={"checkbox"}
                  placeholder={"checkbox"}
                />
              )}

              <label
                className="pl-3 lg:text-[18px] text-[14px] text-Textdarkcolor font-medium"
              >
                <span>
                  I agree & Signed the
                  <a
                    onClick={()=> openModal()}
                    className="underline cursor-pointer pl-1 text-Appcolor hover:text-blue-600 visited:text-purple-600"
                  >
                    Rentals Reimagined Agreement
                  </a>
                </span>
              </label>
            </div>

            {formik.errors.checkbox &&
              formik.touched.checkbox &&
              !agreementCheck && (
                <div className="text-red-500">{formik.errors.checkbox}</div>
              )}
          </div>

          <div className="mt-5">
            <div className="flex">
              {!formik.values.Termcheckbox ? (
                <input
                  id={1}
                  name="Termcheckbox"
                  onClick={
                    !formik.values.Termcheckbox ? openTermModal : openTermModal
                  }
                  checked={formik.values.Termcheckbox}
                  type="checkbox"
                  placeholder="Termcheckbox"
                />
              ) : (
                <input
                  id={1}
                  name={"Termcheckbox"}
                  checked={formik.values.Termcheckbox}
                  type={"checkbox"}
                  placeholder={"Termcheckbox"}
                />
              )}

              <label 
                className="pl-3 lg:text-[18px] text-[14px] text-Textdarkcolor font-medium"
              >
                <span>
                  I agree
                  <a
                    onClick={
                      !formik.values.Termcheckbox
                        ? openTermModal
                        : openTermModal
                    }
                    className="underline cursor-pointer pl-1 text-Appcolor hover:text-blue-600 visited:text-purple-600"
                  >
                   Terms and Condition
                  </a>
                </span>
              </label>

              <label 
                className="pl-1 lg:text-[18px] text-[14px] text-Textdarkcolor font-medium"
              >
                <span>
                 And
                  <a
                    onClick={ openPrivacyModal }
                    className="underline cursor-pointer pl-1 text-Appcolor hover:text-blue-600 visited:text-purple-600"
                  >
                  Privacy Policy
                  </a>
                </span>
              </label>

            </div>

            {formik.errors.Termcheckbox &&
              formik.touched.Termcheckbox &&
              !termCheck && (
                <div className="text-red-500">{formik.errors.Termcheckbox}</div>
              )}
          </div>

          <div>
            <Button
              divstyle="button w-full  md:mt-[60px] mt-[30px] shadow-[0_5px_40px_-20px_#0075FF] text-white font-medium lg:p-6 p-3 text-[20px]"
              btnname="Sign Up"
              type="submit"
              loading={isLoading}
            />
          </div>

          <div className="w-full text-Textdarkcolor text-center md:my-[60px] mt-[20px] ">
            <a>
              {" "}
              Already have an account?{" "}
              <span
                className="underline  cursor-pointer ml-2"
                onClick={() => navigate("/")}
              >
                {" "}
                Sign In{" "}
              </span>
            </a>
          </div>
        </form>
      </div>

      <Aggrementmodal
        showModal={showModal}
        setShowModal={setShowModal}
        agreementData={responseData}
        setAgreementCheck={formik.setFieldValue}
        isFromSignup={true}
      />

      <TermConditionModal
        showTermModal={showTermModal}
        setTermShowModal={setShowTermModal}
        TermData={termCondition}
        PrivacyData={privacy}
        setTermCheck={formik.setFieldValue}
        isFromSignup={true}
      />



      <PrivacyModal
        showPrivacyModal={showPrivacyModal}
        setPrivacyShowModal={setShowPrivacyModal}
        TermData={termCondition}
        PrivacyData={privacy}
        // setTermCheck={formik.setFieldValue}
        isFromSignup={true}
      />

 






      <ToastContainer limit={1} />
    </div>
  );
};

export default Signup;
