// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// export function PopupModal({ showModal, setShowModal }) {
//   const [accepted, setAccepted] = useState(false);
//   const navigate = useNavigate();

// //   const handleYesClick =async () => {
// //     console.log("havsbdhbvhasbdvh");
// //     setAccepted(true);
// //    await localStorage.setItem('popupAccepted', true);
// //    await localStorage.setItem('popupDismissedDate', Date.now());
// //     setShowModal(false);
// //   };

//   const handleYesClick = async () => {
//     await localStorage.setItem('popupAccepted', true);
//     await localStorage.setItem('popupDismissedDate', Date.now());
//     setAccepted(true);
//     setShowModal(false);
//   };

//   const acc = localStorage.getItem('popupAccepted' );
//   console.log("acc===>",acc);

//   const popacc = localStorage.getItem('popupDismissedDate');

//   console.log("popacc===>",popacc);

//   const handleNoClick = () => {
//     setShowModal(false);
//   };

//   useEffect(() => {
//     const hasAccepted = localStorage.getItem('popupAccepted');
//     if (!hasAccepted) {
//       const dismissedDate = localStorage.getItem('popupDismissedDate');
//       if (dismissedDate) {
//         const oneMonthLater = Number(dismissedDate) + 30 * 24 * 60 * 60 * 1000;
//         if (Date.now() >= oneMonthLater) {
//           // If one month has passed since dismissal, show the modal again
//           setShowModal(true);
//         }
//         else
//         {
//           setShowModal(false);
//         }
//       } else {
//         // If the user has not accepted or dismissed the modal before, show it
//         setShowModal(true);
//       }
//     }
//   }, []);

//   useEffect(() => {
//     const dismissedDate = localStorage.getItem('popupDismissedDate');
//     if (dismissedDate) {
//     //   const oneMonthLater = Number(dismissedDate) + 30 * 24 * 60 * 60 * 1000;
//     const oneMonthLater = Number(dismissedDate) +  60 * 1000;
//       if (Date.now() >= oneMonthLater) {
//         localStorage.removeItem('popupDismissedDate');
//         localStorage.removeItem('popupAccepted');
//       }
//     }
//   }, []);

//   useEffect(() => {
//     if (accepted) {
//       const timer = setTimeout(() => {
//         // After one month, set showModal to true to display the modal again
//         setShowModal(true);
//         // Reset accepted to false for the next time the user accepts
//         setAccepted(false);
//     //   }, 30 * 24 * 60 * 60 * 1000); // Show popup again after one month
// }, 60 * 1000);
//     return () => clearTimeout(timer);
//     }
//   }, [accepted, setShowModal]);

// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import Cookies from '../../assets/Cookies.svg'

// export function PopupModal({ showModal, setShowModal }) {
//   const [accepted, setAccepted] = useState(false);
//   const navigate = useNavigate();

//   const handleYesClick = async () => {
//     await localStorage.setItem('popupAccepted', 'true');
//     await localStorage.setItem('popupDismissedDate', Date.now());
//     setAccepted(true);
//     setShowModal(false);
//   };

//   const handleNoClick = () => {
//     setShowModal(false);
//     localStorage.setItem('popupAccepted', 'false'); // Set declined state
//   };

//   useEffect(() => {
//     const hasAccepted = localStorage.getItem('popupAccepted');
//     if (hasAccepted === 'false') {
//       setShowModal(false); // If declined, don't show the modal
//     } else if (!hasAccepted) {
//       const dismissedDate = localStorage.getItem('popupDismissedDate');
//       if (dismissedDate) {
//         const oneMonthLater = Number(dismissedDate) + 30 * 24 * 60 * 60 * 1000;
//         if (Date.now() >= oneMonthLater) {
//           setShowModal(true); // Show the modal if one month has passed since dismissal
//         } else {
//           setShowModal(false); // Otherwise, don't show the modal
//         }
//       } else {
//         setShowModal(true); // Show the modal if no decision has been made before
//       }
//     }
//   }, []);

//   useEffect(() => {
//     if (accepted) {
//       const timer = setTimeout(() => {
//         setShowModal(true); // Show modal again after one month
//         setAccepted(false);
//       }, 30 * 24 * 60 * 60 * 1000);
//       return () => clearTimeout(timer);
//     }
//   }, [accepted, setShowModal]);

//   return (
//     <>
//       {showModal && (
//         <>
//           <div className="justify-center items-center flex fixed inset-0 z-50">
//             <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
//               <div className="w-full max-w-lg bg-white shadow-lg rounded-md p-5 relative">
//                 <div>
//                     <img src={Cookies} className="w-24 h-24"/>
//                 </div>
//                 <div className="mt-2 mb-8 text-start">
//                   <h4 className="text-lg font-semibold mt-2">
//                     This website uses cookies or similar technologies to
//                     enhance your browsing experience and provide personalized
//                     recommendations, by continuing to use our website, you agree
//                     to our
//                     <></>
//                     <span
//                       className="underline text-Appcolor  cursor-pointer ml-2"
//                       onClick={() => navigate("/settings")}
//                     >
//                       Privacy Policy
//                     </span>
//                   </h4>
//                 </div>
//                 <div className="text-center space-x-4 justify-end flex">

//                 <p

//                     className="px-6 py-2.5 rounded-md text-black text-sm font-semibold border-none outline-none "
//                   >
//                     Cookies Settings
//                   </p>

//                   <button
//                     type="button"
//                     onClick={handleNoClick}
//                     className="px-6 py-2.5 rounded-md text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
//                   >
//                     Decline
//                   </button>
//                   <button
//                     type="button"
//                     onClick={handleYesClick}
//                     className="px-6 py-2.5 rounded-md text-white text-sm font-semibold border-none outline-none bg-Appcolor  "
//                   >
//                     Accept
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
//         </>
//       )}
//     </>
//   );
// }

// export default PopupModal;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import CookiesImage from "../../assets/Cookies.svg";
import moment from "moment/moment";

export function PopupModal({ showModal, setShowModal }) {
  const [accepted, setAccepted] = useState(false);
  const navigate = useNavigate();

  const handleYesClick = () => {
    setAccepted(true);
    setShowModal(false);
    Cookies.set("popupAccepted", "true", { expires: 30 }); // Set cookie to expire in 30 days

    // Cookies.set('popupAccepted', 'true', { expires: 1 / (24 * 60) }); // Set cookie to expire in 1 minute
    Cookies.set("popupDismissedDate", moment.now());
  };

  const handleNoClick = () => {
    setShowModal(false);
    Cookies.set("popupAccepted", "false", { expires: 365 }); // Set cookie to expire in 1 year for declining
  };

  useEffect(() => {
    const hasAccepted = Cookies.get("popupAccepted");
    if (hasAccepted === "false") {
      setShowModal(false); // If declined, don't show the modal
    } else if (!hasAccepted) {
      const dismissedDate = Cookies.get("popupDismissedDate");
      if (dismissedDate) {
        const oneMonthLater = Number(dismissedDate) + 30 * 24 * 60 * 60 * 1000;
        // const oneMonthLater = Number(dismissedDate) + 60 * 1000;
        if (Date.now() >= oneMonthLater) {
          setShowModal(true); // Show the modal if one minute has passed since dismissal
        } else {
          setShowModal(false); // Otherwise, don't show the modal
        }
      } else {
        setShowModal(true); // Show the modal if no decision has been made before
      }
    }
  }, []);

  useEffect(() => {
    if (accepted) {
      //   const monthToMillisconds=2147;
      //   console.warn(monthToMillisconds)
      //   const timer = setTimeout(() => {
      //     setShowModal(true); // Show modal again after  time
      //     setAccepted(false);
      // }, monthToMillisconds); // Show modal again after one month
      //   // }, 60 * 1000); //Show modal again after one minute

      //   return () => clearTimeout(timer);

      let dimissedDate = moment(Cookies.get("popupDismissedDate")).fromNow(
        true
      );
      if (dimissedDate.includes("30 days")) {
        setShowModal(true); // Show modal again after  time
        setAccepted(false);
      }
    }
  }, [accepted]);

  return (
    <>
      {showModal && (
        <>
          <div className="justify-center items-center flex fixed inset-0 z-50">
            <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
              <div className="w-full max-w-lg bg-white shadow-lg rounded-md p-5 relative">
                <div>
                  <img src={CookiesImage} className="w-24 h-24" />
                </div>
                <div className="mt-2 mb-8 text-start">
                  <h4 className="text-lg font-normal mt-2">
                    This website uses cookies or similar technologies to enhance
                    your browsing experience and provide personalized
                    recommendations, by continuing to use our website, you agree
                    to our
                    <></>
                    <span
                      className="underline text-Appcolor  cursor-pointer ml-2"
                      onClick={() => navigate("/signup")}
                    >
                      Privacy Policy
                    </span>
                  </h4>
                </div>
                <div className="text-center space-x-4 justify-end flex">
                  <p className="px-6 py-2.5 rounded-md text-black text-sm font-semibold border-none outline-none ">
                    Cookies Settings
                  </p>

                  <button
                    type="button"
                    onClick={handleNoClick}
                    className="px-6 py-2.5 rounded-md text-black text-sm font-semibold border-none outline-none bg-gray-200 hover:bg-gray-300 active:bg-gray-200"
                  >
                    Decline
                  </button>
                  <button
                    type="button"
                    onClick={handleYesClick}
                    className="px-6 py-2.5 rounded-md text-white text-sm font-semibold border-none outline-none bg-Appcolor  "
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}

export default PopupModal;
