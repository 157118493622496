import React, { useState } from "react";
import Inputfield from "../Inputfiled";
import { useFormik } from "formik";
import { Emailmodal } from "../../utils/schema";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "../Toast";
import Close from '../../assets/Png/Closemodal.png'


export default function EmailModal({ emailModal, setShowemailmodal, setEmailValues }) {

  const user = useSelector((state) => state.user);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Emailmodal,
    onSubmit: (values) => {
      if (user.email === values.email) {
        Toast({ message: "You Can't Enter Your Login Email", isError: true });
      }
      else {
        setEmailValues(prev => [
          ...prev,
          { email: values.email }
        ]);
        setShowemailmodal(false);
        formik.resetForm();
      }
    },
  });

  return (
    <>
      {emailModal ? (
        <>
          <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="  w-[584px]  p-20   mx-auto  ">
              <div className="border-0 rounded-lg shadow-lg  relative flex flex-col w-full bg-white outline-none focus:outline-none">

                <div className="flex bg-Appcolor p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="md:text-[18px] text-[16px]   w-full flex justify-center mx-auto text-white font-medium">
                    Additional Driver's Email
                  </h3>



                  {/* <button className=" w-0 h-0  mt-[-35px] mr-[-2px]"  onClick={() => setShowemailmodal(false)} >
                    <span className="bg-white w-8 h-auto rounded-2xl text-black text-[22px] pt-[-39px] block">
                      ×
                    </span>
                  </button> */}



                  <button className="w-0 h-2 rounded-2xl lg:mt-[-35px] mt-[-32px] lg:mr-[-2px] mr-[-4px]" onClick={() => setShowemailmodal(false)} >
                <span className="  lg:w-[34px] lg:h-[32px] w-[27px] h-[25px]  block  ">
                  <img src={Close}/>
                </span>
              </button>




                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="w-[80%] mx-auto">
                    <Inputfield
                      labelstyle="flex text-[16px] mt-[45px]   text-Inputtextcolor font-semibold"
                      inputStyle="mt-[28px] pb-[20px] w-full pl-0 p-3 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                      Labelname="Enter Email Address"
                      type="email"
                      placeholder="johnathancharles@gmail.com"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      errors={formik.errors.email}
                      touched={formik.touched.email}
                    />
                  </div>
                  <div className="flex flex-col items-center justify-center p-6   rounded-b">
                    <button
                      className="bg-Appcolor text-white my-[50px] font-medium p-3 rounded-md w-[240px] text-sm"
                      type="submit"
                    >
                      Add Email
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <ToastContainer limit={1} />

          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

