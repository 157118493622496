import React from 'react';

const Fileupload = ({ name, id, onChange, error, touched, value, onBlur, labelname, placeholder, image, maindiv, noimgclass }) => {
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        onChange({ target: { name, value: selectedFile } });
    };

    return (
        <div className="focus:outline-none lg:mt-[41px] mt-[20px] ">
            <label className="block text-[18px] text-Textdarkcolor font-medium">{labelname}</label>
            <div className={`flex mt-4 pb-2 justify-between text-Textlitecolor flex-end items-center${maindiv}`}>
                <label htmlFor={id} className="cursor-pointer w-[100%] flex items-center border-[#f5f5f5] border-b-[1px]">
                    <input
                        id={id}
                        className="sr-only"
                        type="file"
                        name={name}
                        onChange={handleFileChange}
                        onBlur={onBlur}
                        accept="image/png, image/jpg, image/jpeg "
                    />
                    <span className="w-[100%] cursor-pointer pb-2 flex justify-between flex-row-reverse">
                        <img src={image} className="h-[20px] w-[20px]" alt="Logo" />
                        {value ? (
                            <span className="text-Inputtextcolor text-sm">
                                {value.name} ({value.type})
                            </span>
                        ) : (
                            placeholder
                        )}
                    </span>
                </label>
            </div>
            {error && touched && <div className="text-red-500">{error}</div>}
        </div>


    );
};

export default Fileupload;

