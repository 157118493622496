import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Map from "../Map";
import Search from "../../assets/search.svg";
import { get } from "../../utils/axiosInstance";
import {
  getIsPointInsidePolygon,
  isLocationInLineStringAndNotInPolygon,
  isPointInPolygon,
} from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setAddress, setBookedDates, setCart, setExtraCart } from "../../state";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "../Toast";

const Searchmap = ({ switchComponent, goToNextStep, goToPreviousStep }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [addresses, setAddresses] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [ploygonData, setPloygonData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [rawData, setRawData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const storedAddress = await localStorage.getItem("query");

      if (storedAddress) {
        handleChange(storedAddress);
        handleSelect(storedAddress);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const Token = useSelector((state) => state.token);

  const selectedVendor = useSelector((state) => state.selectedVendor);
  const user_selected_vendor_id = useSelector(
    (state) => state.user_selected_vendor_id
  );
  const vendorId = selectedVendor?.id ?? user_selected_vendor_id;

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    get(`/vendor/geofencing/${vendorId}`, {}, {}, Token)
      .then((res) => {
        const geoFenceData = res.response.data.geo_fence;
        let allFeatures = [];
        setMapData(geoFenceData);
        console.warn(res);
        geoFenceData.forEach((fence) => {
          if (fence.geofencing.features.length > 0) {
            allFeatures.push(...fence.geofencing.features);
          }
        });
        const allCoordinates = [];
        setRawData(geoFenceData);
        geoFenceData.forEach((item) => {
          const newData = item.geofencing.features.flatMap((feature) => {
            return extractCoordinates(feature.geometry);
          });
          allCoordinates.push(...newData);
        });
        setData(allCoordinates);
        setAllData(data);
      })
      .catch((err) => {
        console.error("API Error:", err);
      });
  };
  const extractCoordinates = (geometry) => {
    let coordinates = [];

    if (geometry.type === "LineString") {
      coordinates = geometry.coordinates.map((coordinate) => ({
        lat: coordinate[1],
        lng: coordinate[0],
      }));
    } else if (geometry.type === "Polygon") {
      coordinates = geometry.coordinates[0].map((coordinate) => ({
        lat: coordinate[1],
        lng: coordinate[0],
      }));
    }

    return coordinates;
  };

  const handleChange = (newAddress) => {
    setAddresses(newAddress);
  };

  const handleBackClick = () => {
    switchComponent("Searchaddress");
    goToPreviousStep();
  };

  const handleDoneLocationClick = () => {
    if (selectedLocation) {
      if (ploygonData == true) {
        console.error("Error: No polygon data available.");
        return;
      }
      // const isWithinPolygon = getIsPointInsidePolygon(
      //   selectedLocation.location,
      //   data
      // );
      const isWithinPolygon = isLocationInLineStringAndNotInPolygon(
        selectedLocation.location,
        rawData
      );
      if (isWithinPolygon) {
        localStorage.setItem(
          "selectedAddressData",
          JSON.stringify({
            latitude: selectedLocation.location.lat,
            longitude: selectedLocation.location.lng,
            full_address: selectedLocation.address,
          })
        );
        let address = {
          latitude: selectedLocation.location.lat,
          longitude: selectedLocation.location.lng,
          full_address: selectedLocation.address,
        };
        dispatch(setAddress(address));
        dispatch(setBookedDates([]));
        dispatch(setExtraCart([]));
        dispatch(setCart(null));
        switchComponent("Searchmapform");
        goToNextStep();
      } else {
        Toast({ message: "Can not select restricted area", isError: true });
      }
    } else {
      Toast({ message: "Please select a location first", isError: true });
    }
  };

  // const handleDoneLocationClick = () => {
  //   if (selectedLocation) {
  //     const isWithinPolygon = isPointInPolygon(
  //       selectedLocation.location,
  //       // allData
  //     );

  //     if (isWithinPolygon) {
  //       localStorage.setItem(
  //         "selectedAddressData",
  //         JSON.stringify({
  //           latitude: selectedLocation.location.lat,
  //           longitude: selectedLocation.location.lng,
  //           full_address: selectedLocation.address,
  //         })
  //       );
  //       let address = {
  //         latitude: selectedLocation.location.lat,
  //         longitude: selectedLocation.location.lng,
  //         full_address: selectedLocation.address,
  //       };
  //       dispatch(setAddress(address));
  //       dispatch(setBookedDates([]));
  //       dispatch(setExtraCart([]));
  //       dispatch(setCart(null));
  //       switchComponent("Booking");
  //       goToNextStep();
  //     } else {
  //       Toast({ message: "Can not select restricted area", isError: true });
  //     }
  //   } else {
  //     Toast({ message: "Please select a location first", isError: true });
  //    }
  // };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      setSelectedLocation({
        address: address,
        location: latLng,
      });
    } catch (error) {
      console.error("Error selecting address", error);
    }
  };

  const _renderFunction = () => {
    return (
      <Map
        containerStyle={{
          width: "90%",
          height: "340px",
          marginLeft: "20px",
        }}
        wayPoints={data}
        wayPointsPolygon={ploygonData}
        myMapBoundries={mapData}
        selectedLocation={selectedLocation}
        placesAutocomplete={
          <PlacesAutocomplete
            value={addresses}
            onChange={handleChange}
            onSelect={(selectedAddress) => {
              setAddresses(selectedAddress);
              handleSelect(selectedAddress);
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({
                    // value={addresses}
                    placeholder: "Search address",
                    className:
                      "z-0 block w-full outline-0 lg:p-3 p-2 lg:pl-10 pl-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50",
                  })}
                />
                <div>
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                    };
                    return (
                      <div
                        className="p-3 rounded-sm cursor-pointer"
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion, { style })}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        }
      />
    );
  };
  return (
    <div className="bg-[#FFFFFF] rounded-[10px] flex flex-col justify-center items-center w-[100%] md:h-[670px] h-[87vh]">
      <p className="lg:my-[27px] my-[30px] text-[22px] font-bold text-Textdarkcolor w-[90%]">
        Search LSV
      </p>
      <div className="lg:w-[60%] w-[95%] h-[100%] overflow-y-auto  ">
        <form>
          <label className="flex justify-center  mt-[35px] text-[16px] font-semibold text-Textdarkcolor">
            Select Location
          </label>
          <div className="relative lg:w-[80%] w-[80%] lg:mr-0 mr-2 lg:mx-12 mx-8 z-10 my-2 top-[60px]">
            <div className="absolute inset-y-0 lg:-left-1 lg:-top-8 left-2 -top-10 flex items-center pl-3 pointer-events-none">
              <img
                className="lg:w-4 lg:h-4 w-3 h-3 text-gray-500"
                src={Search}
              />
            </div>
          </div>
          <div>{_renderFunction()}</div>
        </form>
      </div>
      <div className="flex justify-between w-[90%] lg:my-10 my-7">
        <button
          className="bg-Btncolor text-Textdarkcolor lg:text-[16px] text-[12px] rounded-[10px] lg:w-[145px] lg:h-[50px]  md:w-[130px] md:h-[40px] w-[110px] h-[35px]"
          onClick={handleBackClick}
        >
          Back
        </button>
        <button
          className="bg-Appcolor text-white lg:text-[16px] text-[12px] rounded-[10px] lg:w-[145px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[110px] h-[35px] disabled:bg-slate-500"
          onClick={handleDoneLocationClick}
          disabled={selectedLocation !== null ? false : true}
        >
          Done Location
        </button>
      </div>
      <ToastContainer limit={1} />
    </div>
  );
};

export default Searchmap;
