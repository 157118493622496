import React, { useState } from "react";
const Dropdown = ({
    label,
    id,
    name,
    value,
    options,
    onChange,
    error,
    touched,
    disableoption,
    divstyle,
    labelstyle,
    placeholder,
}) => {
    const [menuVisible, setMenuVisible] = useState(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const handleSelect = (selectedValue) => {
        onChange({ target: { name, value: selectedValue, id } });

        toggleMenu();
    };

    // const handleSelect = (selectedValue) => {
    //     onChange(selectedValue);
    //     toggleMenu();
    // };

    return (
        <div>
            <div className={divstyle}>
                <label htmlFor={id} className={labelstyle}>
                    {label}
                </label>
                <div className="w-full relative inline-block text-left">
                    <div className="py-2 flex w-full justify-between" onClick={toggleMenu}>
                        <label className="text-Textlitecolor" placeholder={placeholder}>
                            {value || placeholder}
                        </label>
                        <svg
                            className={`h-5 w-5 text-gray-400 ${menuVisible ? "transform rotate-180" : ""}`}
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </div>

                    {menuVisible && (
                        <div
                            className="absolute right-0 z-10 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                        >
                            <div className="py-1  cursor-pointer" role="none">
                                {options.map((option, index) => (
                                    <div
                                        key={option.value}
                                        onClick={() =>
                                            handleSelect(
                                                option?.item != null ? option?.item : option.value
                                            )
                                        }
                                        // onClick={() => handleSelect(option)}

                                        className="text-gray-700 block px-4 py-2 text-sm"
                                        role="menuitem"
                                        tabIndex="-1"
                                        id={`menu-item-${index}`}
                                    >
                                        {option.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <select
                    id={id}
                    name={name}
                    value={value}
                    onChange={onChange}
                    className={`py-[10px]  w-full   ${touched && error ? "" : ""}`}
                    style={{ display: "none" }}
                >
                    <option className="text-Textdarkcolor" value="" hidden>
                        {disableoption}
                    </option>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            {touched && error && <div className="text-red-500">{error}</div>}
        </div>
    );
};

export default Dropdown;
