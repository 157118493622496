import { Spinner } from "@material-tailwind/react";
import React from "react";

const Button = ({
  divstyle,
  btnstyle,
  btnname,
  type,
  onPress,
  disabled,
  loading,
}) => {
  return (
    <>
      <button
        className={divstyle}
        type={type}
        onClick={onPress}
        disabled={disabled}
      >
        {loading ? (
          <div className="align-middle flex caret-Btncolor justify-center ">
            <Spinner
              className="spinner "
              isLoading={loading}
            />
          </div>
        ) : (
          btnname
        )}
      </button>
    </>
  );
};

export default Button;
