import React from "react";
import Bin from '../../assets/binnn.svg'

const Searchcarttwo = ({ apiData, onDelete, showDelete = true }) => {
    const renderData = () => {
        return apiData.map((item, index) => (
            // <div key={index}>
            <div key={index} className="bg-white   flex-col font-medium rounded-xl shadow-[10px_2px_30px_2px_#00000014] py-5  mx-auto w-[97%] mb-10">
                {showDelete && (
                    <div className="cursor-pointer md:mr-3 mr-1 w-5 ml-auto relative">
                        <img
                            src={Bin}
                            className="cursor-pointer md:w-5 w-3"
                            alt="Delete Icon"
                            onClick={() => onDelete(item.id)}
                        />
                    </div>
                )}
                <div className={`   -my-2 pl-5 `}>
                    <ul className="flex justify-between ">
                        <li className="text-Inputtextcolor md:text-[16px] text-[12px] w-[50%]">Category</li>
                        <li className="md:text-[16px] text-[12px] w-[50%] text-[#0E2740] capitalize">
                            {item.cart_type}
                        </li>
                    </ul>

                    <ul className="flex justify-between ">
                        <li className="text-Inputtextcolor md:text-[16px] text-[12px] w-[50%]">Color</li>
                        <li className="md:text-[16px] text-[12px] w-[50%] text-[#0E2740] capitalize">{item.color}</li>
                    </ul>

                    <ul className="flex justify-between ">
                        <li className="text-Inputtextcolor md:text-[16px] text-[12px] w-[50%]">Make</li>
                        <li className="md:text-[16px] text-[12px] w-[50%] text-[#0E2740] capitalize">{item.make}</li>
                    </ul>

                    <ul className="flex justify-between ">
                        <li className="text-Inputtextcolor md:text-[16px] text-[12px] w-[50%]">Model</li>
                        <li className="md:text-[16px] text-[12px] w-[50%] text-[#0E2740] capitalize">{item.model}</li>
                    </ul>

                    {/* <ul className="flex justify-between ">
                        <li className="text-Inputtextcolor md:text-[16px] text-[12px] w-[50%]">License plate #</li>
                        <li className="md:text-[16px] text-[12px] w-[50%] text-[#0E2740] capitalize">{item.license_plate_number}</li>
                    </ul> */}

                    <ul className="flex justify-between ">
                        <li className="text-Inputtextcolor md:text-[16px] text-[12px]  w-[50%]">Class</li>
                        <li className="md:text-[16px] text-[12px] w-[50%] text-[#0E2740] capitalize">{item.category.name}</li>
                    </ul>

                    {/* <ul className="flex justify-between ">
                        <li className="text-Inputtextcolor md:text-[16px] text-[12px] w-[50%]">Drive</li>
                        <li className="md:text-[16px] text-[12px] w-[50%] text-[#0E2740] capitalize">{item.drive}</li>
                    </ul> */}
                </div>

            </div >
        ));

    };
    return (
        <div className="bg-[#FFFFFF] rounded-[10px]  flex flex-col justify-center items-center w-[100%] ">
            <p className="md:my-[10px] ml-3 my-[20px]  md:text-[22px] text-[18px] font-bold text-Textdarkcolor w-full text-start">
                Extra LSV Details
            </p>
            <div className="bg-white   flex-col font-medium rounded-xl     mx-auto w-[97%] ">
                <div className="flex flex-col  w-[100%] text-start md:pt-10 pt-0">
                    {renderData()}
                </div>
            </div>
        </div>
    );

};

export default Searchcarttwo;
