import React, { useState } from 'react';
import Sidenav from '../../components/Sidenav';
import Homecard from '../../components/Homecard';
import Avatar from '../../assets/avatar.svg'
import { Logout } from '../Modals/Logout';
import Reservationcard from './Reservationcard';
import { useSelector } from 'react-redux';

const Reservations = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const user = useSelector((state) => state.user);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    return (

        <div className="w-[100%] flex  flex-row">
            <div className=" lg:w-[9%] md:w-[12%]  ">
                <Sidenav selectedOne={3} />
            </div>
            <div className="w-[100%]  flex flex-col">
                {/* <div>
                    <img onClick={toggleDropdown} className='w-[10%] m-4 ml-auto w-8 h-8 cursor-pointer' src={Avatar} />
                </div> */}
                <div className='flex items-center justify-end mx-6 cursor-pointer' onClick={toggleDropdown}>
                    <img className='my-[6px]  h-6 cursor-pointer' src={Avatar} />
                    <p className="pl-3 font-bold md:text-[16px] text-[13px]">{user.first_name} {user.last_name}</p>
                </div>
                <Reservationcard />
            </div>
            <Logout isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} />
        </div>
    );
};

export default Reservations;
