import React, { useState } from 'react';
import Vendorhomecard from './Vendorhomecard';
import Sidenav from '../Sidenav';
import Stepper from '../Stepper';

const Home = () => {

    const [currentStep, setCurrentStep] = useState(0);

    const goToNextStep = () => {
        if (currentStep < 2) {
            setCurrentStep(currentStep + 1);
        }
    };

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };
    return (

        <div className="w-[100%] flex flex-row">
            <div className="lg:w-[9%] md:w-[12%] ">
                {/* <Vendorsidenav selectedOne={0} /> */}
                <Sidenav selectedOne={0} />
            </div>
            <div className="w-[100%] flex flex-col">
                <Stepper currentStep={currentStep} />
                <Vendorhomecard goToNextStep={goToNextStep} goToPreviousStep={goToPreviousStep} />
            </div>
        </div>
    );
};

export default Home;
