import React, { useState, useEffect } from 'react'
import Vendoraddressinfo from './Vendoraddressinfo'
import Vendorcartinfo from './Vendorcartinfo'
import Availablecart from './Availablecart'
import Sidecard from '../Home/Sidecard'
import Successmodal from '../Modals/Successmodal';
import { useSelector } from 'react-redux';

const Vendorhomecard = ({ goToNextStep, goToPreviousStep,
    // currentView = "Vendoraddressinfo" 
}) => {
    const user = useSelector((state) => state.user);
    window.onload = function () {
    };

    const [activeComponent, setActiveComponent] = useState(() => {
        const hasCart = user && user.address && user.cart;

        if (hasCart) {
            return "Availablecart";
        } else {
            return "Vendoraddressinfo";
        }
    });

    const Property = activeComponent === "Availablecart" || activeComponent === "Vendoraddressinfo" || activeComponent === "Vendorcartinfo";

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
    }, []);

    // let componentToRender;

    // if (user && user.address && user.cart !== undefined) {
    //     componentToRender = <Availablecart user={user} />;

    // } else {
    //     componentToRender = (
    //         <Vendoraddressinfo
    //             switchComponent={setActiveComponent}
    //             goToNextStep={goToNextStep}
    //             goToPreviousStep={goToPreviousStep}
    //         />
    //     );
    // }

    const renderComponent = () => {
        switch (activeComponent) {
            case 'Availablecart':
                return <Availablecart user={user} />
            case 'Vendoraddressinfo':
                return <Vendoraddressinfo
                    Property={Property}
                    switchComponent={setActiveComponent}
                    goToNextStep={goToNextStep}
                    goToPreviousStep={goToPreviousStep} />
            case 'Vendorcartinfo':
                return <Vendorcartinfo Property={Property} switchComponent={setActiveComponent} goToPreviousStep={goToPreviousStep} />;
            case 'SuccessModal':
                return <Successmodal switchComponent={setActiveComponent} goToNextStep={goToNextStep} />
            default:
                return null;
        }
    };
    return (
        <div className="w-[100%] md:mt-5 md:py-8 pt-3 pb-3 flex justify-center h-[100%] bg-Btncolor">
            <div className="   md:w-[30%] hidden lg:block">
                {Property ? <Sidecard Property={Property} />
                    :
                    <Sidecard />
                }

            </div>
            <div className=" lg:w-[60%]  w-[90%] flex flex-col">
                {renderComponent()}
            </div>

        </div>
    );
};

export default Vendorhomecard;











