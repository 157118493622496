import React, { useState, useEffect } from "react";
import Avatar from "../assets/avatar.svg";
import { Logout } from "./Modals/Logout";
import { useSelector } from "react-redux";

const Stepper = ({ currentStep }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [ownerSteps, setOwnerSteps] = useState([]);
    const user = useSelector((state) => state.user);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const guestSteps = [
        { label: "Select Vendor", currentStep: 0 },
        { label: "Select and Add Location", currentStep: 1 },
        { label: "Search LSV", currentStep: 2 },
        { label: " Booking Process", currentStep: 3 },
        { label: "Summary", currentStep: 4 },
    ];

    useEffect(() => {
        if (user.address && user.cart !== null) {
            setOwnerSteps([]);
        } else {
            setOwnerSteps([
                { label: "Address Details", currentStep: 0 },
                { label: "LSV Details", currentStep: 1 },
            ]);
        }
    }, [user.address, user.cart]);

    return (
        <div>
            <aside className="lg:hidden md:block pl-8 shadow-[0_20px_40px_rgba(0,0,0,0.05)] px-2 py-1">
                <div className="grid w-[100%] mx-auto grid-cols-2 justify-end items-center">
                    <div className="col-span-1 sm:w-[145%] w-[165%] gap-3 flex justify-end">
                        {user.user_type === "guest" &&
                            guestSteps.map((step, index) => (
                                <div key={index}>
                                    <p className={`sm:text-[16px] text-[12px]  ${currentStep === step.currentStep
                                        ? "text-Appcolor font-bold"
                                        : "text-Textlitecolor font-semibold"
                                        }`} >
                                        Step {index + 1}
                                    </p>
                                </div>
                            ))}

                        {user.user_type === "property-owner" &&
                            ownerSteps.map((step, index) => (
                                <div key={index}>
                                    <p
                                        className={`sm:text-[16px] text-[12px] ${currentStep === step.currentStep
                                            ? "text-Appcolor"
                                            : "text-Textlitecolor"
                                            }`} >
                                        Step {index + 1}
                                    </p>
                                </div>
                            ))}
                    </div>
                    <div onClick={toggleDropdown} className="flex justify-end cursor-pointer shadow-[0_20px_40px_rgba(0,0,0,0.05)]" >
                        <img className="w-6 h-6" src={Avatar} />
                    </div>
                </div>
            </aside>
            <aside className="w-[100%] lg:block hidden shadow-[0_20px_40px_rgba(0,0,0,0.05)] md:p-2 p-0">
                <div className="flex justify-center items-center">
                    {user.user_type === "guest" ?
                        <p className="text-Appcolor font-semibold text-[22px] lg:mx-[25px] xl:mx-0 mx-0">
                            Reservation Process
                        </p>
                        : null
                    }

                    <div className="flex justify-evenly w-[70%]">
                        {user.user_type === "guest" &&
                            guestSteps.map((step, index) => (
                                <div key={index}>
                                    <p className="text-[13px] text-Textlitecolor">
                                        Step {index + 1}
                                    </p>
                                    <p className={`font-bold text-[16px] ${currentStep === step.currentStep
                                        ? "text-Appcolor" : "text-Textlitecolor"}`}  >
                                        {step.label}
                                    </p>
                                </div>
                            ))}

                        {user.user_type === "property-owner" && (
                            ownerSteps.map((step, index) => (
                                <div key={index}>
                                    <p className="text-[13px] text-Textlitecolor">
                                        Step {index + 1}
                                    </p>
                                    <p className={`font-bold text-[16px] ${currentStep === step.currentStep
                                        ? "text-Appcolor " : "text-Textlitecolor"}`} > {step.label}
                                    </p>
                                </div>
                            ))
                        )}

                    </div>
                    <div onClick={() => toggleDropdown()}
                        className="flex w-[12%] justify-end cursor-pointer items-center" >
                        <img className="w-8 h-8" src={Avatar} />
                        <p className="pl-3 font-bold">{user.first_name} {user.last_name}</p>
                    </div>
                </div>
            </aside>
            <Logout
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={setIsDropdownOpen}
            />
        </div>
    );
};

export default Stepper;
