import React, { useState, useEffect } from 'react';
import Sidenav from '../../components/Sidenav';
import Homecard from '../../components/Homecard';
import Avatar from '../../assets/avatar.svg'
import Settingscard from './Settingscard';
import { Logout } from '../Modals/Logout';
import { useSelector } from 'react-redux';

const Settings = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const user = useSelector((state) => state.user);
    useEffect(() => {
        getUserData()
    }, []);
    const getUserData = () => {
        // let data = localStorage.getItem('user');
        
    }
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    return (

        <div className="w-[100%] flex  flex-row">
            <div className=" lg:w-[9%] md:w-[12%]  ">
                <Sidenav selectedOne={7} />
            </div>
            <div className="w-[100%]  flex flex-col">
                <div className='flex items-center md:w-[95%] justify-end md:my-[10px] my-0  mx-5 cursor-pointer' onClick={toggleDropdown}>
                    <img className='my-[6px] md:my-[4px] md:h-8  h-6 cursor-pointer' src={Avatar} />
                    <p className="pl-3 font-bold">{user.first_name} {user.last_name}</p>
                </div>
                <Settingscard />
            </div>

            <Logout isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} />
        </div>
    );
};

export default Settings;
