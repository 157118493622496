import axios from 'axios';
import { BASE_URL } from './constants';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});


axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error("Response Error:", error); // Debugging response error
    if (error.response && error.response.status === 401) {
      console.log("Unauthorized access - 401. Logging out and redirecting.",);
      if (error.response?.data?.error?.messages[0] !== "Email or password is invalid") {
        localStorage.clear();
        window.location.href = '/';
      }
    }
    return Promise.reject(error);
  }
);

export const get = async (url, params = {}, headers = {}, token) => {
  try {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axiosInstance.get(url, { params, headers });
    return response.data;
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
};

export const post = async (url, data = {}, headers = {}, token) => {
  // console.log("POST_DATA==>", data);
  try {
    if (token) {
      headers['Accept'] = 'application/json';
      headers['Authorization'] = `Bearer ${token}`;
    }
    let config = { headers };

    if (data instanceof FormData) {
      config.headers = { ...headers, 'Content-Type': 'multipart/form-data' };
    }

    const response = await axiosInstance.post(url, data, config);
    return response.data;
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
};

export const put = async (url, data = {}, headers = {}, token) => {
  try {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axiosInstance.put(url, data, { headers });
    return response.data;
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
};

export const patch = async (url, data = {}, headers = {}, token) => {
  try {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axiosInstance.patch(url, data, { headers });
    return response.data;
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
};

export const del = async (url, headers = {}, token) => {
  try {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await axiosInstance.delete(url, { headers });
    return response.data;
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
};

const handleRequestError = (error) => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      console.log("Request failed with status code:", error.response.status);
      console.log("Response data:", error.response.data);
    } else if (error.request) {
      console.log("No response received. Check your network connection.");
    } else {
      console.log("Error setting up the request:", error.message);
    }
  } else {
    console.log("Non-Axios error:", error.message);
  }
};
export default axiosInstance;