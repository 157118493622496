import React, { useState, useEffect } from "react";
import Edit from "../../assets/edit.svg";
// import Profilecard from "../../assets/Png/Profilecard.png";
import Inputfield from "../Inputfiled";
import { useFormik } from "formik";
import { Profileschema } from "../../utils/schema";
import { get, patch, post } from "../../utils/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../Spinner";
import { ImageModal } from "./Imagemodal";
import Imageview from "../../assets/imageview.svg";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../Toast";

import countriesData from "../auth/Countries.json";
import { useNavigate, useLocation } from "react-router-dom";


const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);

  const [selectedCountry, setSelectedCountry] = useState(user.country || "");
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    user.country_code || ""
  );
  const [phoneNumber, setPhoneNumber] = useState(user.phone || "");

 
  //   useEffect(() => {
  //     setLoader(true);
  //     getUserData();
  //   }, []);

   const navigate = useNavigate();


  useEffect(() => {
    setLoader(true);
    getUserData();
    setSelectedCountry(user.country || "");
    setSelectedCountryCode(user.country_code || "");
    setPhoneNumber(user.phone || "");
  }, []);

  const getUserData = () => {
    setLoader(true);

    get(`/user/user/${user.id}`, {}, {}, Token)
      .then((res) => {
        setLoader(false);
        const user = res.response.data.user;
        setUserData(user);
        formik.setValues({
           first_name: user.first_name || "",
          last_name: user.last_name || "",
          email: user.email || "",
          phone: user.phone || "",
          address1: user.address1 || "",
          address2: user.address2 || "",
          city: user.city || "",
          unit_apt: user.unit_apt || "",
          state: user.state || "",
          country_code: user.country_code || "",
          country: user.country || "",

          zip: user.zip || "",
          ...(user.user_type === "guest"
            ? {
                user_license: user.user_license.media || "",
                user_insurance: user.user_insurance.media || "",
              }
            : {}),
        });
      })
      .catch((err) => {
        setLoader(false);
        console.error("API Error:", err);
      });
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      unit_apt: "",
      country: "",
      country_code: "",
      state: "",
      zip: "",
       user_license: "",
      user_insurance: "",
      _method: "PATCH",
    },
    validationSchema: Profileschema,
    onSubmit: (values) => {
      if (user.user_type === "guest") {
        const dataPayload = {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          address1: values.address1,
          address2: values.address2,
          city: values.city,

          //   phone: values.phone,
          //   country_code:
          //   country: values.country,

          country: values.country, // Include selectedCountry
          country_code: values.country_code, // Include selectedCountryCode
          phone: values.phone, // Include phoneNumber

          unit_apt: values.unit_apt,
          state: values.state,
          zip: values.zip,
          _method: "PATCH",
        };
        handleFormSubmit(dataPayload);
      } else {
        handleFormSubmit();
      }
    },
  });

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleFormSubmit = () => {
    if (!formik.errors || Object.keys(formik.errors).length === 0) {
      // const Token = localStorage.getItem('token');
      // const userId = localStorage.getItem("userId");

      get(`/user/user/${user.id}`, {}, {}, Token)
        .then((res) => {
          const user = res.response.data.user;

          // const fieldsToUpdate = Object.keys(dataPayload).filter(field => {
          //     return dataPayload[field] !== user[field];
          // });

          // const updatePayload = {};
          // fieldsToUpdate.forEach(field => {
          //     updatePayload[field] = dataPayload[field];
          // });

          // if (Object.keys(updatePayload).length > 0) {

          //   const dataPayload = {
          //     first_name: formik.values.first_name,
          //     last_name: formik.values.last_name,
          //     email: formik.values.email,
          //     // phone: formik.values.phone,
          //     address1: formik.values.address1,
          //     address2: formik.values.address2,
          //     city: formik.values.city,
          //     unit_apt: formik.values.unit_apt,
          //     state: formik.values.state,

          //     zip: formik.values.zip,
          //     // _method: "PATCH",
          //     country: selectedCountry,
          //     country_code: selectedCountryCode,
          //     phone: phoneNumber,
          //     _method: "PATCH",
          //   };

          //   const dataPayload = {
          //     first_name: formik.values.first_name,
          //     last_name: formik.values.last_name,
          //     email: formik.values.email,
          //     address1: formik.values.address1,
          //     address2: formik.values.address2,
          //     city: formik.values.city,
          //     unit_apt: formik.values.unit_apt,
          //     state: formik.values.state,
          //     zip: formik.values.zip,
          //     country: selectedCountry, // Include selectedCountry
          //     country_code: selectedCountryCode, // Include selectedCountryCode
          //     phone: phoneNumber, // Include phoneNumber
          //     _method: "PATCH",
          //   };

          const dataPayload = {
            first_name: formik.values.first_name,
            last_name: formik.values.last_name,
            email: formik.values.email,
            address1: formik.values.address1,
            address2: formik.values.address2,
            city: formik.values.city,
            unit_apt: formik.values.unit_apt,
            state: formik.values.state,
             zip: formik.values.zip,
            country: formik.values.country, // Use formik.values.country
            country_code: formik.values.country_code, // Use formik.values.dial_code
            phone: formik.values.phone,
            _method: "PATCH",
          };
          patch(`/user/update`, dataPayload, {}, Token)
            .then((profileRes) => {
              setIsEditing(false);
              // toast.success('Profile Updated Successfully', {
              //     position: toast.POSITION.BOTTOM_RIGHT,
              // });
              Toast({
                message: "Profile Updated Successfully",
                isError: false,
              });
            })
            .catch((profileErr) => {
              Toast({
                message: `Error updating profile: ${profileErr}`,
                isError: true,
              });
              // console.error('Error updating profile:', profileErr);
            });
          // }
          // else {
          //     console.log('No fields are being updated');
          // }
        })
        .catch((err) => {
          toast.warning("API Error", err, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
  };

  const handleInsuranceFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      formik.setFieldValue("user_insurance", {
        target: { name: "user_insurance", value: file },
      });
      handleInsuranceUpdate(file);
    }
  };

  const handleInsuranceUpdate = (file) => {
    var formdata = new FormData();
    formdata.append("insurance", file, "insurance.png");
    formdata.append("_method", "PATCH");
    post(`/user/update-insurance`, formdata, {}, Token)
      .then((licenseRes) => {
        toast.success("License Updated Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        formik.setFieldValue(
          "user_insurance",
          licenseRes.response.data.user.user_insurance.media
        );
      })
      .catch((licenseErr) => {
        console.error("Error updating license:", licenseErr);
      });
  };

  const handleLicenseFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      formik.setFieldValue("user_license", {
        target: { name: "user_license", value: file },
      });
      handleLicenseUpdate(file);
    }
  };


  const handleCountryChange = (event) => {
    const selectedCountryName = event.target.value;
    const selectedCountry = countriesData.find(
      (country) => country.name === selectedCountryName
    );
    const dialCode = selectedCountry ? selectedCountry.dial_code : "";

    setSelectedCountry(selectedCountryName);
    setSelectedCountryCode(dialCode);

    formik.setValues({
      ...formik.values,
      country: selectedCountryName,
      country_code: dialCode,
      phone: "",
    });
  };

  const handlePhoneNumberChange = (event) => {
    const phoneNumberValue = event.target.value;

    setPhoneNumber(phoneNumberValue);

    formik.setValues({
      ...formik.values,
      phone: phoneNumberValue,
    });
  };

  const handleLicenseUpdate = (file) => {
    var formdata = new FormData();
    formdata.append("license", file, "license.png");
    formdata.append("_method", "PATCH");
    post(`/user/update-license`, formdata, {}, Token)
      .then((licenseRes) => {
        // console.log('Response for updating license:', licenseRes);
        toast.success("License Updated Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        formik.setFieldValue(
          "user_license",
          licenseRes.response.data.user.user_license.media
        );
      })
      .catch((licenseErr) => {
        console.error("Error updating license:", licenseErr);
      });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  if (loader) {
    return (
      <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
        <Spinner className="spinner" isLoading={loader} />
      </div>
    );
  } else {
    return (
      <div className="bg-[#FFFFFF] rounded-[10px] flex flex-col justify-center items-center w-[100%] md:h-[670px] h-[360px]">
        <p className="flex justify-between border-b-[1px] border-[#EDF0F6]   w-[90%] ">
          <span className="lg:text-[22px] text-[16px] font-bold mt-[27px]">
            My Profile
          </span>
          {!isEditing && (
            <img
              src={Edit}
              className="md:w-[30px] md:h-[30px] w-[20px] h-[20px] mt-[27px]  cursor-pointer"
              onClick={handleEditClick}
            />
          )}
        </p>

        <div className="p-4 lg:w-[50%] w-[90%] custom-scrollbar">
          <form onSubmit={formik.handleSubmit}>
            {/* <img src={Profilecard} className='w-[216px] mt-10 h-[137px] mx-auto' /> */}

            <div>
              <Inputfield
                labelstyle="flex md:text-[18px] text-[14px] md:mt-[41px] mt-[10px] text-Inputtextcolor font-medium"
                inputStyle="mt-1 w-full md:text-[16px] text-[13px] pl-0 md:p-3 p-2 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                Labelname="First Name"
                type="text"
                placeholder="Enter your First Name"
                name="first_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                errors={formik.errors.first_name}
                touched={formik.touched.first_name}
                isEditing={true}
                disabled={!isEditing}
              />

              <Inputfield
                labelstyle="flex md:text-[18px] text-[14px] md:mt-[41px] mt-[10px] text-Inputtextcolor font-medium"
                inputStyle="mt-1 w-full  md:text-[16px] text-[13px] pl-0 md:p-3 p-2 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                Labelname="Last Name"
                type="text"
                placeholder="Enter your Last Name"
                name="last_name"
                htmlFor="last_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
                errors={formik.errors.last_name}
                touched={formik.touched.last_name}
                isEditing={true}
                disabled={!isEditing}
              />

              {isEditing ? null : (
                <Inputfield
                  labelstyle="flex md:text-[18px] text-[14px] md:mt-[41px] mt-[10px] text-Inputtextcolor font-medium"
                  inputStyle="mt-1 w-full md:text-[16px] text-[13px] pl-0 md:p-3 p-2 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                  Labelname="Email"
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  htmlFor="email"
                  id="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  errors={formik.errors.email}
                  touched={formik.touched.email}
                  isEditing={true}
                  disabled={!isEditing}
                />
              )}

              <label className="flex md:text-[18px] text-[14px] md:mt-[41px] mt-[10px] text-Inputtextcolor font-medium">
                Phone
              </label>

              {isEditing ? (
                <div className="w-full mt-1 pl-0 p-3 border-b-[1px] border-[#f5f5f5]">
                  <select
                    className="md:w-[10%] w-[16%] focus:outline-none"
                    value={formik.values.country}
                    onChange={handleCountryChange}
                    style={{ maxHeight: "150px" }}
                  >
                    {/* {countriesData.map((country) => (
                      <option key={country.code} value={country.name}>
                        {country.name} {country.flag}
                      </option>
                    ))} */}

                    {countriesData.map((country) => (
                      <option
                        className="md:text-[16px] text-[80px]  "
                        key={country.code}
                        value={country.name}
                      >
                        {formik.values.country === country.name ? (
                          <span role="img" aria-label="Flag">
                            {country.flag}
                          </span>
                        ) : (
                          <>
                            <p className=" country-name bg-red-400">
                              {country.name} {country.flag}
                            </p>
                          </>
                        )}
                      </option>
                    ))}
                  </select>

                  <input
                    className="md:ml-2 ml-1 md:w-[10%] w-[14%] focus:outline-none"
                    type="text"
                    value={formik.values.country_code}
                    onChange={formik.handleChange}
                    disabled={!isEditing}
                  />

                  <input
                    className="md:ml-2 ml-1 md:w-[65%] w-[14%] focus:outline-none"
                    type="text"
                    value={formik.values.phone}
                    onChange={handlePhoneNumberChange}
                    disabled={!isEditing}
                  />
                </div>
              ) : (
                <>
                  <input
                    className="md:ml-2 ml-1 md:w-[14%] w-[14%] focus:outline-none"
                    type="text"
                    value={formik.values.country}
                    onChange={handleCountryChange}
                    readOnly
                    disabled={!isEditing}
                  />

                  <input
                    className="md:ml-2 ml-1 md:w-[8%] w-[14%] focus:outline-none"
                    type="text"
                    value={formik.values.country_code}
                    readOnly
                    disabled={!isEditing}
                  />

                  <input
                    type="text"
                    disabled={!isEditing}
                    className="md:ml-2 ml-1 md:w-[65%] w-[65%] focus:outline-none"
                    value={formik.values.phone}
                  />
                </>
              )}
            
              <Inputfield
                labelstyle="flex md:text-[18px] text-[14px] md:mt-[41px] mt-[10px] text-Inputtextcolor font-medium"
                inputStyle="mt-1 w-full md:text-[16px] text-[13px] pl-0 md:p-3 p-2 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                Labelname="Address 1"
                type="text"
                placeholder="Enter your Address1 1"
                name="address1"
                htmlFor="address1"
                id="address1"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address1}
                errors={formik.errors.address1}
                touched={formik.touched.address1}
                isEditing={true}
                disabled={!isEditing}
              />

              <Inputfield
                labelstyle="flex md:text-[18px] text-[14px] md:mt-[41px] mt-[10px] text-Inputtextcolor font-medium"
                inputStyle="mt-1 w-full md:text-[16px] text-[13px] pl-0 md:p-3 p-2 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                Labelname="Address 2 (Optional)"
                type="text"
                name="address2"
                htmlFor="address2"
                id="address2"
                placeholder="Enter your Address 2"
                value={formik.values.address2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isEditing={true}
                disabled={!isEditing}
              />

              <Inputfield
                labelstyle="flex md:text-[18px] text-[14px] md:mt-[41px] mt-[10px] text-Inputtextcolor font-medium"
                inputStyle="mt-1 w-full md:text-[16px] text-[13px] pl-0 md:p-3 p-2 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                Labelname="City"
                type="text"
                placeholder="Enter your City"
                name="city"
                htmlFor="city"
                id="city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                errors={formik.errors.city}
                touched={formik.touched.city}
                isEditing={true}
                disabled={!isEditing}
              />

              <Inputfield
                labelstyle="flex md:text-[18px] text-[14px] md:mt-[41px] mt-[10px] text-Inputtextcolor font-medium"
                inputStyle="mt-1 w-full md:text-[16px] text-[13px] pl-0 md:p-3 p-2 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                Labelname="Unit"
                type="text"
                placeholder="Enter your Unit"
                name="unit_apt"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.unit_apt}
                errors={formik.errors.unit_apt}
                touched={formik.touched.unit_apt}
                isEditing={true}
                disabled={!isEditing}
              />

              <Inputfield
                labelstyle="flex md:text-[18px] text-[14px] md:mt-[41px] mt-[10px] text-Inputtextcolor font-medium"
                inputStyle="mt-1 w-full md:text-[16px] text-[13px] pl-0 md:p-3 p-2 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                Labelname="State"
                type="text"
                placeholder="Enter your state"
                name="state"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
                errors={formik.errors.state}
                touched={formik.touched.state}
                isEditing={true}
                disabled={!isEditing}
              />
              <Inputfield
                labelstyle="flex md:text-[18px] text-[14px] md:mt-[41px] mt-[10px] text-Inputtextcolor font-medium"
                inputStyle="mt-1 w-full md:text-[16px] text-[13px] pl-0 md:p-3 p-2 focus:outline-none border-b-[1px] border-[#f5f5f5]"
                Labelname="Zipcode"
                type="text"
                placeholder="Enter your zip"
                name="zip"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.zip}
                errors={formik.errors.zip}
                touched={formik.touched.zip}
                isEditing={true}
                disabled={!isEditing}
              />

              {user.user_type === "guest" ? (
                <>
                  <div className="flex flex-col">
                    <label className="flex md:text-[18px] text-[14px] md:mt-[21px] mt-[9px] text-Inputtextcolor font-medium">
                      User License
                    </label>
                    <label
                      className="relative inline-flex items-center md:my-2 mt-1 justify-center w-[100%] h-[230px] border-[1px] rounded-[10px] border-gray-300 cursor-pointer bg-[#F8F8F8]"
                      //  onClick={() => openImageModal(formik.values.user_license)}
                      // onClick={() => console.log("hello")}
                    >
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        name="user_license"
                        className="absolute object-contain inset-0 w-full h-full opacity-0 cursor-pointer"
                        onChange={handleLicenseFileChange}
                        onBlur={formik.handleBlur}
                        onFocus={() =>
                          formik.setFieldTouched("user_license", true)
                        }
                        disabled={!isEditing}
                      />
                      {formik.values.user_license ? (
                        <div className="w-full flex flex-row-reverse  relative">
                          <img
                            src={formik.values.user_license}
                            alt="User Insurance Thumbnail"
                            className="w-[100%] h-[225px] object-fill rounded-[10px]"
                          />
                          <img
                            src={Imageview}
                            alt="User Insurance Thumbnail"
                            className="w-[21px] object-fill mr-[7px] absolute z-50 bottom-0 h-[28px]   ml-auto mt-auto"
                            onClick={() =>
                              openImageModal(formik.values.user_license)
                            }
                          />
                        </div>
                      ) : (
                        <div className="w-full h-full flex items-center justify-center">
                          <span className="text-gray-500">
                            No Image Selected
                          </span>
                        </div>
                      )}
                    </label>
                    {formik.errors.image && formik.touched.image && (
                      <div className="text-start text-red-500 ">
                        {formik.errors.image}
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <label className="flex md:text-[18px] text-[14px] md:mt-[21px] mt-[9px] text-Inputtextcolor font-medium">
                      User Insurance{" "}
                    </label>
                    <label className="relative inline-flex items-center mt-2 justify-center w-[100%] h-[230px] border-[1px] rounded-[10px] border-gray-300 cursor-pointer bg-[#F8F8F8]">
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        name="user_insurance"
                        className="absolute object-contain inset-0 w-full h-full opacity-0 cursor-pointer"
                        onChange={handleInsuranceFileChange}
                        onBlur={formik.handleBlur}
                        onFocus={() =>
                          formik.setFieldTouched("user_insurance", true)
                        }
                        disabled={!isEditing}
                      />
                      {formik.values.user_insurance ? (
                        <div className="w-full flex flex-row-reverse  relative">
                          <img
                            src={formik.values.user_insurance}
                            alt="User Insurance Thumbnail"
                            className="w-[100%] h-[225px] object-fill rounded-[10px]"
                          />
                          <img
                            src={Imageview}
                            alt="User Insurance Thumbnail"
                            className="w-[21px]  mr-[7px] absolute z-50 bottom-0 h-[28px] object-contain ml-auto mt-auto"
                            onClick={() =>
                              openImageModal(formik.values.user_insurance)
                            }
                          />
                        </div>
                      ) : (
                        <div className="w-full h-full flex items-center justify-center">
                          <span className="text-gray-500">
                            No Image Selected
                          </span>
                        </div>
                      )}
                    </label>
                    {formik.errors.user_insurance &&
                      formik.touched.user_insurance && (
                        <div className="text-start text-red-500 ">
                          {formik.errors.user_insurance}
                        </div>
                      )}
                  </div>
                </>
              ) : null}
            </div>
            <div className="mt-4 w-full">
              {isEditing && (
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white p-4 rounded-[10px]"
                >
                  Save & Update
                </button>
              )}
            </div>
          </form>
        </div>

        {selectedImage && (
          <ImageModal
            imageUrl={selectedImage}
            onClose={() => setSelectedImage(null)}
          />
        )}

        {/* <ToastContainer limit={1} /> */}
      </div>
    );
  }
};

export default Profile;
