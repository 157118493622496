import React from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import MarkerImage from '../assets/Png/icon_store_marker.png'

function VendorMap({ containerStyle, onClick, stores, selectedLocation }) {
    const isLoaded =true

    const options = {
        disableDefaultUI: false,
        streetViewControl: false,
        fullscreenControl: false,
        draggable: true,
        mapTypeControl: false,
        zoom: 14,
    };

    const allLocations = [
        ...(stores
            ? stores.map((vendor) => ({
                lat: +vendor?.latitude,
                lng: +vendor?.longitude,
            }))
            : []),
    ];

    function getRandomNumber() {
        return Math.random() * (101 - 1) + 1;
    }
  
    return (
        <div className="App">
            {!isLoaded ? (
                <h1>Loading...</h1>
            ) : (
                <GoogleMap
                    options={options}
                    mapContainerStyle={containerStyle}
                    center={allLocations.length !== 0 ? { lat: allLocations[0].lat, lng: allLocations[0].lng } : { lat: 0, lng: 0 }}
                    zoom={10}
                >
                    {allLocations.map((location, index) => {

                        return (
                            <Marker key={`${getRandomNumber()}`}
                                position={{ lat: location.lat, lng: location.lng }}
                                icon={{
                                    url: MarkerImage,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                }} />
                        )
                    }
                    )}
                </GoogleMap>
            )}
        </div>
    );
}

export default VendorMap;

