import React, { useState, useEffect } from "react";
import { get, post } from "../../utils/axiosInstance";
import noimage from "../../assets/noimage.svg";
import Spinner from "../Spinner";
import Searchcarttwo from "./Searchcarttwo";
import { useDispatch, useSelector } from "react-redux";
import { setExtraCart, setSelectedValue } from "../../state";
import Dropdown from "../Dropdown";
import Inputfield from "../Inputfiled";
import Toast from "../Toast";
import { ToastContainer } from "react-toastify";
import Tick from "../../assets/tick.svg";

const Searchmapform = ({ switchComponent, goToNextStep, goToPreviousStep ,setCurrentStep}) => {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.token);
  const assigned_cart = useSelector((state) => state.assigned_cart);
  const extraCart = useSelector((state) => state.extra_cart);
  const storedAddress = useSelector((state) => state.address);
  const [loader, setLoader] = useState(false);
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [err, setErr] = useState("");

  useEffect(() => {
    getCategories();
    if (extraCart.length !== 0) {
      setVisible(true);
    }
     else {
      setVisible(false);
    }
  }, []);

  const getCategories = () => {
    get("/category", {}, {}, Token)
      .then((res) => { 
        setLoader(false)
        const categoryData = res.response.data.categories;
        setCategories(categoryData);
      })
      .catch((err) => {
        setLoader(false)
        Toast({ message: err, isError: true });
      });
  };

  const [selectedCategory, setSelectedCategory] = useState(null); // State to track the selected category


  const handleDoneButtonClick = () => {
    if (selectedCategory) {
      dispatch(setSelectedValue(selectedCategory));
      switchComponent("Booking");
    } else {
      Toast({ message: "Please select a LSV preference", isError: true });
    }
  };


  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId); 
  };

 

  if (loader) {
    return (
      <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
        <Spinner className="spinner" isLoading={loader} />
      </div>
    );
  } else {
    return (
      <div className="bg-[#FFFFFF] rounded-[10px]  flex flex-col justify-between items-center  w-[100%]  md:h-[670px] h-[87vh]">
        <p className="md:my-[27px] my-[20px] md:text-[22px] text-[18px] font-bold text-Textdarkcolor w-[89%]">
        LSV Details & Description
        </p>

        <div className=" w-[100%] h-[100%] px-4  ">
          <div>
            <div className=" py-0  custom-scrollbar h-[670px] md:h-[450px] mx-auto flex justify-center w-[90%]">
              <div className="flex flex-col  w-[90%]    ">
                <Inputfield
                  labelstyle="flex text-[18px] text-Inputtextcolor font-medium"
                  inputStyle="mt-1 w-full pl-0 p-3 focus:outline-none text-black border-b-[1px] border-[#f5f5f5]"
                  Labelname="Address"
                  type="text"
                  name="address"
                  value={storedAddress.full_address}
                  isEditing={true}
                  disabled={true}
                />
 

 
<p className="flex text-[18px] mt-2 text-Textdarkcolor font-medium">LSV Preference</p>

<div className="grid xl:grid-cols-3 my-7 lg:place-items-center lg:grid-cols-2 grid-col-1 gap-y-4 gap-x-4">

      {categories.map((category, index) => ( 
        <div key={index} onClick={() => handleCategoryClick(category.id)} className="relative flex flex-col items-center cursor-pointer hover:shadow-md w-full">
          {selectedCategory === category.id && (
            <span className="absolute top-0 right-0 bg-Appcolor rounded-2xl w-[30px] h-[30px]">
              <img draggable={false} src={Tick} alt="Tick" className="absolute top-0 right-0" />
            </span>
          )}
          <img width={250} src={category.media } alt={category.name} />
          <p className="text-center">{category.name}</p> 
        </div>
      ))}
    </div>



                {/* <Dropdown
                  labelstyle="flex text-[18px]  text-Textdarkcolor font-medium"
                  divstyle="mt-4 w-full border-b-[1px] focus:outline-none border-[#f5f5f5] "
                  label="LSV Preference"
                  id="cart"
                  name="cart"
                  placeholder="Choose your preferred LSV passengers"
                  options={categories.map((category) => ({
                    id: category.id,
                    value: category.name,
                    label: category.description,
                    item: category,
                  }))}
                  value={selectedCategory?.name ?? null}
                  onChange={(e) => {
                    setselectedCategory(e.target.value);

                    // dispatch(setSelectedValue(selectedCategory));
                  }}
                  error={err}
                /> */}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between w-[90%] lg:my-10 my-5">
          <button
            className="bg-Btncolor text-Textdarkcolor lg:text-[16px] text-[12px] rounded-[10px] lg:w-[145px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[120px] h-[40px]"
            onClick={() => {
              dispatch(setExtraCart([]));
              setCurrentStep(1)
              switchComponent("Searchmap");
              goToPreviousStep();
            }}
          >
            Back
          </button>

          <div>
            <button
              className="bg-Appcolor text-white lg:text-[16px] text-[12px] rounded-[10px] lg:w-[145px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[110px] h-[35px]"
              disabled={!selectedCategory}
              onClick={() => {
                handleDoneButtonClick();
              }}
            >
              Done
            </button>
          </div>
        </div>
        <ToastContainer limit={1} />
      </div>
    );
  }
};

export default Searchmapform;
