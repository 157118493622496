import React, { useEffect, useRef, useState } from "react";
import Sidenav from "../../components/Sidenav";
import Stepper from "../../components/Stepper";
import Homecard from "../../components/Homecard";
import { useDispatch, useSelector } from "react-redux";
import { get } from "../../utils/axiosInstance";
import Lottie from "lottie-react";
import { setSelectedVendor } from "../../state";

const Home = () => {
  const dispatch = useDispatch()
  const userJourneyVendorId = useSelector((state) => state.useJourneyVendor);
  const selectedVendor = useSelector((state) => state.selectedVendor);
  const Token = useSelector((state) => state.token);
  const [currentStep, setCurrentStep] = useState(0);
  const [isVendor, setIsVendor] = useState(false);
  const homeCardRef = useRef();


 
  async function data() {
    get(`/reservation-guide`, {}, {}, Token)
      .then((res) => {
        const response = res.response?.data?.reservation_guide;
        // setShowResponse(response)
      })
      .catch((err) => {
        console.log("Response Error Show", err);
      });
  }

  const goToNextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };
  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };


  const getVandorId = () => {
    get(`/vendor/${userJourneyVendorId}`, {}, {}, Token)
      .then((res) => {
        // console.log("vendor", res);
        if ("response" in res) {
          let data = res.response.data.vendor
          dispatch(setSelectedVendor(data));
          setIsVendor(true)
        } else {
          setIsVendor(true)
          console.log("The selected store is not available at the moment")
        }
      })
      .catch((err) => {
        console.log(err);
        setIsVendor(true)
        console.log("The selected store is not available at the moment")

      });
  };

  useEffect(() => {
    userJourneyVendorId !== null && getVandorId();
  }, [userJourneyVendorId]);


  useEffect(() => {
    data()
  }, []);


  return (
    <div className="w-[100%] flex flex-row">
      <div className="lg:w-[9%] md:w-[12%]">
        <Sidenav selectedOne={0} innerRef={homeCardRef} />
      </div>
      <div className="w-[100%] flex flex-col">
        <Stepper currentStep={currentStep} />
        {
          isVendor === true && selectedVendor == null ? (
            <div className="justify-center items-center w-[100%] h-[670px]  p-4 flex flex-col">
              <Lottie animationData={require('../../assets/sorryLottie.json')}
                style={{
                  width: "70%",
                  height: "70%"
                }} />
              <p className="text-Appcolor">The selected store is not available at the moment</p>
            </div>
          ) : (
            <Homecard
              innerRef={homeCardRef}
              isVendor={isVendor}
              setCurrentStep={setCurrentStep}
              goToNextStep={goToNextStep}
              goToPreviousStep={goToPreviousStep}
            />

          )
        }

      </div>
    </div>
  );
};

export default Home;
