import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
  Polygon,
} from "@react-google-maps/api";

function Map({
  containerStyle,
  onClick,
  selectedLocation,
  placesAutocomplete,

  myMapBoundries
}) {
  const isLoaded = true;

  const [center, setCenter] = useState({ lat: 30.3, lng: -86 });
  const [map, setMap] = useState(null);

  const moveToLocation = (location) => {
    setCenter(location);
    map && map.panTo(location);
  };

  useEffect(() => {
    if (selectedLocation) {
      moveToLocation(selectedLocation.location);
    }
  }, [selectedLocation]);

  const [markers, setMarkers] = useState([]);
  const whiteMapStyle = [
    {
      elementType: "geometry",
      stylers: [{ color: "#f8f7f7" }],  
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#000000" }],  
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#ffffff" }], 
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [{ visibility: "on" },{"color":"#d5d9e6"},{"lightness":14},{"weight":1.4}]
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [{ color: "#d1dbe3" }],  
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#808080" }, { "lightness": 14 }, { "weight": 1.4 }],  
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [{ visibility: "off" }],  
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [{ color: "#90daee" }, { "lightness": 14 }, { "weight": 1.4 }],  
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [{ color: "#f8f7f7" }],  
    },
    {
      featureType: "building.fill",
      elementType: "geometry.stroke",
      stylers: [{ color: "#e8e9ed" }, { "lightness": 14 }, { "weight": 1.4 }],  
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [{ "color": "#e8e9ed" }]
    },
  ];
  ;
  const options = {
    disableDefaultUI: true,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    zoom: 12,
    center: center,
    styles: whiteMapStyle,
  };
  const handleMapClick = (event) => {
    const latLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    const newMarker = {
      latLng: latLng,
      id: markers.length,
    };

    setMarkers([...markers, newMarker]);
    onClick && onClick(latLng);
  };
  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={options.center}
        zoom={options.zoom}
        options={options}
        onClick={handleMapClick}
      >
        {placesAutocomplete && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              width: "90%",
              margin: "20px 20px 0px 20px",
            }}
          >
            {placesAutocomplete}
          </div>
        )}
        {selectedLocation && <Marker position={selectedLocation.location} />}

        {myMapBoundries.map(item => (
          item.geofencing.features.map(feature => {
            if (feature.geometry.type === 'LineString') {
              return (
                <Polyline
                  key={feature.properties.id}
                  options={{
                    strokeColor: "#0075FF",
                    strokeWeight: 2,
                    fillColor: "#0075FF",
                    fillOpacity: 0.35,
                  }}
                  path={feature.geometry.coordinates.map(coordinate => ({
                    lat: coordinate[1],
                    lng: coordinate[0],
                  }))}
                />
              );
            } else if (feature.geometry.type === 'Polygon') {
              return (
                <Polygon
                  key={feature.properties.id}
                  options={{
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35,
                  }}
                  paths={feature.geometry.coordinates[0].map(coordinate => ({
                    lat: coordinate[1],
                    lng: coordinate[0],
                  }))}
                />
              );
            }
            return null;
          })
        ))}
      </GoogleMap>
    </div>
  ) : (
    <div>Loading...</div>
  );
}

export default Map;
